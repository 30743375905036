import {useEffect, useState} from "react";
import EventForm from "./EventForm";
import {useSelector} from "react-redux";


const EventFactory = ({props, fetchFactory}) => {
    const {pin, event} = useSelector(state => state.events);
    const [eventInfo, setEventInfo] = useState({
        name: null,
        spotName: null,
        street: null,
        streetNumber: null,
        city: null,
        zipCode: null,
        country: null,
        dateStart: null,
        dateEnd: null,
        timeStart: null,
        timeEnd: null,
        description: null,
        prizes: null,
        registrationFee: null,
        entryFee: null,
        tiktok: null,
        instagram: null,
        facebook: null,
        youtube: null,
        website: null,
        merch: null,
        tickets: null,
        donate: null,
        pin: null,
        isImgNull: true
    });
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [categories, setCategories] = useState([]);
    const [organizers, setOrganizers] = useState([]);
    const [judges, setJudges] = useState([]);
    const [lecturers, setLecturers] = useState([]);
    const [battleGuests, setBattleGuests] = useState([]);
    const [mcs, setMcs] = useState([]);
    const [djs, setDjs] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [type, setType] = useState([]);

    useEffect(() => {

        if (props.title === "Edit Event") {
            setEventInfo({
                ...eventInfo,
                id: event.id,
                pin: pin,
                name: event.name,
                spotName: event.spotName,
                street: event.street,
                streetNumber: event.streetNumber,
                city: event.city,
                zipCode: event.zipCode,
                country: event.country,
                dateStart: event.dateStart,
                dateEnd: event.dateEnd,
                timeStart: event.timeStart,
                timeEnd: event.timeEnd,
                description: event.description,
                prizes: event.prizes,
                registrationFee: event.registrationFee,
                entryFee: event.entryFee,
                tiktok: event.tiktok,
                instagram: event.instagram,
                facebook: event.facebook,
                youtube: event.youtube,
                website: event.website,
                merch: event.merch,
                tickets: event.tickets,
                donate: event.donate,
                isImgNull: event.isImgNull ? event.isImgNull : true
            });
            setImagePreview(event.image);
            setCategories(event.categories);
            setJudges(event.judges);
            setLecturers(event.lecturers);
            setBattleGuests(event.battleGuests);
            setMcs(event.mcs);
            setDjs(event.djs);
            setSponsors(event.sponsors);
            setOrganizers(event.organizers);
            setType(event.type);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const isImgNull = !image && !imagePreview;
        setEventInfo((prevEventInfo) => ({
            ...prevEventInfo,
            isImgNull
        }));
        //eslint-disable-next-line
    }, [image, imagePreview]);


    const handleChange = (e) => {
        e.preventDefault();
        const {name, value, files} = e.target;
        switch (name) {
            case "image":
                const file = files[0];
                setImage(file);
                break;
            default:
                setEventInfo({...eventInfo, [name]: value});
                break;
        }
    }

    const wrongInputsHandler = () => {
        if (!eventInfo.dateStart) {
            alert("Date is required");
            return true;
        }

        if (!eventInfo.name) {
            alert("Name is required");
            return true;
        }

        if (!eventInfo.dateEnd) {
            eventInfo.dateEnd = eventInfo.dateStart;
        }

        if (eventInfo.dateStart > eventInfo.dateEnd) {
            alert("End date must be after start date");
            return true;
        }

        if (eventInfo.timeStart && eventInfo.timeEnd && (eventInfo.dateStart === eventInfo.dateEnd)) {
            const startValue = Number(eventInfo.timeStart.split(":")[0]) * 60 + Number(eventInfo.timeStart.split(":")[1]);
            const endValue = Number(eventInfo.timeEnd.split(":")[0]) * 60 + Number(eventInfo.timeEnd.split(":")[1]);

            if (eventInfo.dateStart.toString() === eventInfo.dateEnd && startValue > endValue && endValue !== 0) {
                alert("End time must be after start time");
                return true;
            }
        }

        //this removes empty keys from eventInfo object because Select component sucks and adds empty keys when searching
        Object.keys(eventInfo).forEach(key => {
            if (key === null || key === undefined || key === "") {
                delete eventInfo[key];
            }
        });

        return false;
    }


    const createUpdateEvent = (e) => {
        e.preventDefault();

        if (wrongInputsHandler()) return;

        const formData = new FormData();
        formData.append("image", image);
        formData.append("eventInfo", JSON.stringify(eventInfo));
        formData.append("categories", JSON.stringify(categories));
        formData.append("judges", JSON.stringify(judges));
        formData.append("lecturers", JSON.stringify(lecturers));
        formData.append("battleGuests", JSON.stringify(battleGuests));
        formData.append("mcs", mcs);
        formData.append("djs", djs);
        formData.append("sponsors", sponsors);
        formData.append("organisers", organizers);
        formData.append("type", type);

        fetchFactory(eventInfo, formData);
    }

    return (
        <div>
            <EventForm eventInfo={eventInfo}
                       setEventInfo={setEventInfo}
                       setImage={setImage}
                       handleChange={handleChange}
                       createUpdateEvent={createUpdateEvent}
                       imagePreview={imagePreview} setImagePreview={setImagePreview}
                       categories={categories} setCategories={setCategories}
                       judges={judges} setJudges={setJudges}
                       lecturers={lecturers} setLecturers={setLecturers}
                       battleGuests={battleGuests} setBattleGuests={setBattleGuests}
                       mcs={mcs} setMcs={setMcs}
                       djs={djs} setDjs={setDjs}
                       sponsors={sponsors} setSponsors={setSponsors}
                       organizers={organizers} setOrganizers={setOrganizers}
                       type={type} setType={setType}
                       props={props}
            />
        </div>
    );
}
export default EventFactory
