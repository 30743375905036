import {useSelector} from "react-redux";
import Socials from "../../containers/Socials";
import React from "react";


function Profile() {
    const dancer = useSelector(state => state.user.currentUser);
    return (
        <div className="profile-container" >
            <div className="profile-header">
                <Socials holder={dancer} />
            </div>

            <div className={`profile-body`}>
                <div className={`profile`}>
                    <div className={`profile__image`}>
                        {dancer.photo ? <img src={dancer.photo} alt="profile"/> :
                            <img src="https://i.imgur.com/tyjxiCH.png" alt="profile"/>}
                    </div>
                    <div className={`profile__info`}>
                        <h2>{dancer.username}</h2>
                        <p>{dancer.firstName} {dancer.lastName}</p>
                        <div className={'crewContainer'}>
                            {dancer.crews.map((crew, index) =>
                                <p key={`${index}`}> {crew.name} {index !== dancer.crews.length - 1 &&
                                    <span>,</span>} </p>)}
                        </div>
                        <p>{dancer.city}</p>
                        <p>{dancer.country}</p>
                        <p>{dancer.nationality}</p>
                    </div>
                </div>
                <div className={`about`}>
                    <h2>About</h2>
                    <p>{dancer.about}</p>
                </div>
                <div className={`events`}>
                    <h2>Events</h2>
                    {dancer.events.map((event, index) =>
                        <a href={event.link || '#'} key={index} target="_blank" rel="noopener noreferrer">
                            <p> {event.date} / {event.city} / {event.name} </p>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Profile;