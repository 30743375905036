import React from "react";
import {Link, useLocation} from "react-router-dom";
import {
    PlusCircleIcon,
    // HomeIcon,
    // DeviceTabletIcon,
    // ClipboardListIcon,
    CalendarIcon,
    CashIcon
} from "@heroicons/react/outline";

const menuItems = [
    {icon: <CalendarIcon className="icon"/>, text: "EVENTS", link: "/"},
    // { icon: <ClipboardListIcon className="icon" />, text: "MY EVENTS", link: "/my-events" },
    // { icon: <DeviceTabletIcon className="icon" />, text: "BROADCAST", link: "/broadcast" },
    {icon: <PlusCircleIcon className="icon"/>, text: "CREATE", link: "/create-event"},
    {icon: <CashIcon className="icon"/>, text: "DONATE", link: "/donate"}
];

const Menu = () => {
    const location = useLocation();

    return (
        <div className="bg-custom-800 rounded-md hidden lg:block xl:w-200 p-4 overflow-auto">
            {menuItems.map((menuItem, index) => (
                <div className="flex items-center justify-center xl:justify-start hover:text-primary mt-4 ml-2"
                     key={index}>
                    <Link to={menuItem.link}
                          className={`link ${location.pathname === menuItem.link ? "text-primary" : ""} flex `}>
                        <svg className="h-8 w-8 md:h-6 md:w-6">{menuItem.icon}</svg>
                        <p className="ml-4 hidden xl:block font-bold">{menuItem.text}</p>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default Menu;
