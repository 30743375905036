import React, {useState} from "react";
import {TrashIcon} from "@heroicons/react/outline";

function PeopleModel({people, setPeople, id, title, name}) {
    const [person, setPerson] = useState("")

    const handlePersonChange = (e) => {
        setPerson(e.target.value)
    }
    const addPerson = (e) => {
        e.preventDefault()
        if (person === "") return;
        if (title === "Judges" || title === "Lecturers" || title === "Battle Guests") {
            setPeople([...people, {username: person}]);
        } else {
            setPeople([...people, person]);
        }
        document.getElementById(id).value = "";
        setPerson("");
    }

    const handleKeyPress = (e) => {
        e.preventDefault();
        if (e.key === "Enter") {
            addPerson(e);
        }
    }

    const removePerson = (e, index) => {
        e.preventDefault();
        const updatedPeople = [...people];
        updatedPeople.splice(index, 1);
        setPeople(updatedPeople);
    }

    const toggleTrashIcon = (e) => {
        e.currentTarget.querySelector(".button-people").classList.toggle("hidden")
    }

    function showTeam(eventElement, single, plural) {
        return (
            <>
                <div className={`flex gap-4 ${!eventElement[0] && `hidden`} rounded-md py-2 px-4`}>
                    <div className="text-custom-gray w-70">
                        <p className={`${eventElement.length < 2 ? "" : "hidden"}`}>{single}:</p>
                        <p className={`${eventElement.length > 1 ? "" : "hidden"}`}>{plural}:</p>
                    </div>
                    <div className="ml-2">
                        {eventElement.map((post, index) => {
                            return (
                                <div className="flex justify-between gap-5 items-center px-5"
                                     key={index}
                                     onPointerEnter={toggleTrashIcon}
                                     onPointerLeave={toggleTrashIcon}>
                                    <p className="text-xl flex">{post.username ? post.username : post}</p>
                                    <button
                                        className="button-people text-gray-500 hover:text-red-500 hidden"
                                        onClick={(e) => removePerson(e, index)}>
                                        <TrashIcon className="h-5 w-5"/>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"flex w-full gap-3"}>
            <div className={"flex flex-col gap-3 mb-6 w-1/2"}>
                <label htmlFor={id} className={"font-bold"}>{title}</label>
                <div>
                    <input className={"input xl:w-4/6"} type={"text"} id={id} placeholder={name} name={"person"}
                           onChange={handlePersonChange}
                           onKeyUp={handleKeyPress}
                    />
                    <button onClick={addPerson} className={`button ml-4`}>Submit</button>
                </div>
            </div>
            <div className={"w-1/2"}>
                <div className={"bg-custom-600 rounded w-3/4 m-2 mt-9"}>
                    {showTeam(people, name, title)}
                </div>
            </div>

        </div>
    )
}

export default PeopleModel;