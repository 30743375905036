import {useSelector} from "react-redux";
import EventFactory from "./EventFactory";
import useEvents from "../../services/useEvents";

function EditEvent() {
    const events = useSelector(state => state.events)
    const eventToEdit = events.events.find(event => event.id === events.idInUse)
    const {updateFetch} = useEvents()

    const props = {
        title: "Edit Event",
        button: "Update",
    }

    return (
        <div className={"p-4"}>
            <EventFactory eventToEdit={eventToEdit} props={props} fetchFactory={updateFetch}/>
        </div>
    )
}

export default EditEvent