import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook, faInstagram, faSoundcloud, faSpotify,
    faTiktok, faTwitch, faTwitter, faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
    faGlobe, faDonate, faTicket, faShoppingBag
} from "@fortawesome/free-solid-svg-icons";

import React, {useEffect} from "react";
import Mobile from "./Mobile";

function Socials({holder}) {

    const socialMedia = {
        instagram: faInstagram,
        tiktok: faTiktok,
        youtube: faYoutube,
        facebook: faFacebook,
        soundcloud: faSoundcloud,
        spotify: faSpotify,
        twitch: faTwitch,
        twitter: faTwitter,
        website: faGlobe,
        donate: faDonate,
        tickets: faTicket,
        merch: faShoppingBag,
    }
    const {mobileSocials} = Mobile();

    useEffect(() => {
        mobileSocials();
    }, [mobileSocials]);

    const checkSocials = (link) => {
        if (!link) return;
        if (link.includes("https://")) return link;
        else return `https://${link}`;
    }

    function showSocials(medium, icon, index) {
        return (
            <a key={index}
               className={`p-3 hover:text-secondary ${holder[medium] ?? holder[medium] === "" ? "" : "hidden"}`}
               href={checkSocials(holder[medium])}
               target="_blank" rel="noopener noreferrer" title={medium}>
                <FontAwesomeIcon icon={icon} className={"fa-icon"}/>
            </a>
        )
    }

    return (
        <div className={`socials`}>
            {Object.entries(socialMedia).map(([medium, icon], index) => showSocials(medium, icon, index))}
        </div>
    )
}

export default Socials;