import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {convertStringYMDToDate, showTeam} from "../../containers/Helpers";
import Mobile from "../../containers/Mobile";
import Socials from "../../containers/Socials";
import useEvents from "../../services/useEvents";
import Underscore from "../../containers/Underscore";
import EventSettingsDropdown from "./EventSettingsDropdown";
import {useSelector} from "react-redux";
import nycCrew from "../../assets/images/9.png";
import Modal from "../../containers/Modal";


function DisplayEvent() {
    const {event, eventLoading, eventError} = useSelector(state => state.events);
    const id = useParams().id;
    const {getEvent} = useEvents();
    const {mobileEvent} = Mobile();

    const [dayNameStart, setDayNameStart] = useState("");
    const [monthStart, setMonthStart] = useState("");
    const [dayStart, setDayStart] = useState("");
    const [dayNameEnd, setDayNameEnd] = useState("");
    const [monthEnd, setMonthEnd] = useState("");
    const [dayEnd, setDayEnd] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleImageClick = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        getEvent(id).then((data) => {
            mobileEvent();
            if (data) {
                const [dayNameStart, monthStart, dayStart] = convertStringYMDToDate(data.dateStart).toDateString().split(" ");
                const [dayNameEnd, monthEnd, dayEnd] = convertStringYMDToDate(data.dateEnd).toDateString().split(" ");
                setDayNameStart(dayNameStart);
                setMonthStart(monthStart);
                setDayStart(dayStart);
                setDayNameEnd(dayNameEnd);
                setMonthEnd(monthEnd);
                setDayEnd(dayEnd);
            }
        });
        // eslint-disable-next-line
    }, []);

    const renderTeamInfo = (teamData, teamType, label) => {
        if (teamData && teamData.length > 0) {
            return (
                <div className={`gray-box mb-2`}>
                    {showTeam(teamData, teamType, label)}
                </div>
            )
        }
    }

    const isTeam = () => {
        return (event.judges && event.judges.length > 0) ||
            (event.lecturers && event.lecturers.length > 0) ||
            (event.battleGuests && event.battleGuests.length > 0) ||
            (event.djs && event.djs.length > 0) ||
            (event.mcs && event.mcs.length > 0) ||
            (event.organizers && event.organizers.length) > 0;
    }

    const isInfo = () => {
        return event.spotName ||
            event.street ||
            event.streetNumber ||
            event.city ||
            event.zipCode ||
            event.country ||
            event.dateStart ||
            event.timeStart ||
            event.timeEnd;
    }

    const isAddress = () => {
        return event.spotName ||
            event.street ||
            event.streetNumber ||
            event.city ||
            event.zipCode ||
            event.country;
    }

    const isCategories = () => {
        return event.categories && event.categories.length > 0;
    }

    const isLeftPanel = () => {
        return isTeam() || isCategories() || event.description;
    }

    const isEventReady = () => {
        return event.dateStart && !eventLoading && !eventError;
    }


    return (
        <div className={"p-4 bg-cover rounded-md xl:h-full"} style={{backgroundImage: `url(${nycCrew})`}}>

            {eventError ? <p className={"ml-10 mt-4 text-red-800"}>Error: {eventError}</p> :
                (eventLoading || !event.dateStart) &&
                <p className={"ml-10 mt-4 animate-pulse"}>The event is loading ...</p>}

            {isEventReady() &&
                <>
                    {/*header*/}
                    <div className="bg-quaternary lg:min-h-10 h-fit mb-2 rounded-md items-center flex flex-col p-3
                            lg:justify-between lg:grid lg:grid-cols-3 lg:p-0">
                        <div>
                            <p className="event-name lg:ml-4 mb-2 lg:mb-0 text-2xl font-bold">{event.name}</p>
                        </div>
                        <div className={"border border-primary w-[150px] lg:hidden"}>
                        </div>
                        <div className="flex justify-center mt-3 lg:mt-0">
                            <Socials holder={event}/>
                        </div>
                        <div className={"flex justify-end pr-6 pb-2 hidden lg:flex"}>
                            <EventSettingsDropdown/>
                        </div>
                    </div>

                    {/*body*/}
                    <div className={`${isLeftPanel() && "lg:flex"} h-line overflow-auto`}>

                        {/*Left panel*/}
                        <div className={`info-panel`}>
                            {event.description &&
                                <div>
                                    <p className={"event-element"}>About</p>
                                    <Underscore/>
                                    <div className={`event-element-value-div gray-box`}>
                                        <pre>{event.description}</pre>
                                    </div>
                                </div>}

                            {/*Team*/}
                            {isTeam() &&
                                <div>
                                    <p className={"event-element"}>Team</p>
                                    <Underscore/>
                                    <div className={"event-element-value-div"}>
                                        {renderTeamInfo(event.organizers, "Organizer", "Organizers")}
                                        {renderTeamInfo(event.judges, "Judge", "Judges")}
                                        {renderTeamInfo(event.lecturers, "Lecturer", "Lecturers")}
                                        {renderTeamInfo(event.battleGuests, "Battle Guest", "Battle Guests")}
                                        {renderTeamInfo(event.djs, "DJ", "DJ's")}
                                        {renderTeamInfo(event.mcs, "MC", "MC's")}
                                    </div>
                                </div>}

                            {/*Categories/Styles*/}
                            {isCategories() && (event.categories[0].vs ? (
                                <div>
                                    <p className={"event-element"}>{event.categories.length > 1 ? "Categories" : "Category"}</p>
                                    <Underscore/>
                                    <div
                                        className={`event-element-value-div 
                                            ${event.categories.length > 1 ? "grid grid-cols-2 gap-2" : ""}`}>
                                        {event.categories.map((category, index) => {
                                            return (
                                                <div key={index}
                                                     className={"bg-custom-600 rounded-md p-3 h-full flex flex-col justify-between"}>
                                                    <div className={"flex flex-col justify-center items-center"}>
                                                        <div className={"flex"}>
                                                            {category.styles.map((style, index) => {
                                                                return (
                                                                    <p key={index}>{style} {index === category.styles.length - 1 ? "" : "-"} &nbsp;</p>
                                                                )
                                                            })}
                                                        </div>
                                                        <p className={"text-primary"}>- {category.vs} -</p>
                                                        <p>{category.age}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className={"event-element"}>{event.categories.length > 1
                                    || event.categories[0].styles.length > 1 ? "Styles" : "Style"}</p>
                                    <Underscore/>
                                    <div
                                        className={`event-element-value bg-custom-600 rounded-md p-4 h-fit`}>
                                        {event.categories.map((category, index) => (
                                            category.styles.map((style, index) => (
                                                <p key={index}>{style}</p>
                                            ))
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/*Right panel*/}
                        <div className={`info-panel right-panel`}>

                            {/*Info*/}
                            {isInfo() &&
                                <div>
                                    <p className={"event-element"}>Event Info</p>
                                    <Underscore/>
                                    <div className={"event-element-value-div"}>
                                        {isAddress() &&
                                            <div>
                                                <div className={`gray-box mb-2`}>
                                                    <p className={"event-element-value-name"}>Address: </p>
                                                    <div>
                                                        <p>{event.spotName}</p>
                                                        <p>{event.street} {event.streetNumber}</p>
                                                        <p>{event.city}</p>
                                                        <p>{event.zipCode}</p>
                                                        <p>{event.country} {event.country && <span
                                                            className={`fi fi-${event.country.toLowerCase()} mx-4`}/>}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>}
                                        <div className={`gray-box mb-2`}>
                                            {event.dateStart === event.dateEnd ?
                                                //one day event
                                                <div className={"xl:flex w-full"}>
                                                    <div className={"flex w-1/2"}>
                                                        <p className={"event-element-value-name"}>Date: </p>
                                                        <p><span
                                                            className={"text-primary"}>{dayNameStart} / </span>{dayStart} {monthStart}
                                                        </p>
                                                    </div>
                                                    {event.timeStart &&
                                                        <div>
                                                            <div className={"flex"}>
                                                                <p className={"event-element-value-name"}>Time: </p>
                                                                <p>{event.timeStart && `${event.timeStart} ${!event.timeEnd ? "" : `- ${event.timeEnd}`}`}</p>
                                                            </div>
                                                        </div>}
                                                </div>
                                                :
                                                //multi day event
                                                <div className={"flex flex-col"}>
                                                    <div className={"flex gap-16"}>
                                                        <div className={"flex"}>
                                                            <p className={"event-element-value-name"}>Starts: </p>
                                                            <p><span className={"text-primary"}>{dayNameStart} / </span>
                                                                {dayStart} {monthStart} {event.timeStart && ` - ${event.timeStart}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className={"flex gap-16"}>
                                                        <div className={"flex"}>
                                                            <p className={"event-element-value-name"}>Ends: </p>
                                                            <p><span className={"text-primary"}>{dayNameEnd} / </span>
                                                                {dayEnd} {monthEnd} {event.timeEnd && `- ${event.timeEnd}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/*Fee*/}
                                    {(event.registrationFee || event.entryFee) &&
                                        <div className={"fee"}>
                                            <p className={"event-element"}>Fee</p>
                                            <Underscore/>
                                            <div className={"event-element-value-div"}>
                                                {event.entryFee &&
                                                    <p className={`gray-box mb-2`}><span
                                                        className={"event-element-value-name"}>Entry: </span>{event.entryFee}
                                                    </p>}
                                                {event.registrationFee &&
                                                    <p className={`gray-box`}><span
                                                        className={"event-element-value-name"}>Registration: </span>{event.registrationFee}
                                                    </p>}
                                            </div>
                                        </div>}
                                </div>
                            }

                            {/*Sponsors*/}
                            {/*{event.sponsors && event.sponsors.length > 0 &&*/}
                            {/*    <div>*/}
                            {/*        <p className={"event-element"}>Sponsors</p>*/}
                            {/*        <Underscore/>*/}
                            {/*        <div className={"event-element-value-div"}>*/}
                            {/*            <div className={`gray-box gap-5`}>*/}
                            {/*                {event.sponsors.map((sponsor, index) => {*/}
                            {/*                    return (*/}
                            {/*                        <p key={index}>{sponsor}</p>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>}*/}

                            {/*Prizes*/}
                            {event.prizes &&
                                <div>
                                    <p className={"event-element"}>Prizes</p>
                                    <Underscore/>
                                    <div className={`gray-box overflow-auto event-element-value-div`}>
                                        <p>{event.prizes}</p>
                                    </div>
                                </div>
                            }

                            {/*Poster*/}
                            {event.image &&
                                <div>
                                    <p className={"event-element"}>Poster</p>
                                    <Underscore/>
                                    <div className={`gray-box event-element-value-div`}>
                                        <img className={"rounded-md"}
                                             src={`data:image/jpeg;base64,${event.image}`}
                                             alt={"Event"}
                                             onClick={handleImageClick}/>
                                    </div>
                                </div>
                            }
                            <Modal
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                                imageSrc={`data:image/jpeg;base64,${event.image}`}
                            />
                        </div>
                    </div>
                </>}
        </div>
    )
}

export default DisplayEvent;