import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    events: [],
    eventsError: null,
    eventsLoading: false,
    event: {},
    eventError: null,
    eventLoading: false,
    pin: "",
    isEventCodeValid: false,
};

const eventsSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        fetchEventsLoading(state) {
            state.eventsError = null;
            state.eventsLoading = true;
        },
        fetchEvents(state, action) {
            state.events = action.payload;
            state.eventsLoading = false;
        },
        fetchEventsFailure(state, action) {
            state.eventsError = action.payload;
            state.loading = false;
        },
        fetchEventLoading(state) {
            state.eventError = null;
            state.loading = true;
        },
        fetchEvent(state, action) {
            state.event = action.payload;
            state.eventLoading= false;
        },
        fetchEventFailure(state, action) {
            state.eventError = action.payload;
            state.eventLoading = false;
        },
        setPin(state, action) {
            state.pin = action.payload;
        },
        setIsEventCodeValid(state, action) {
            state.isEventCodeValid = action.payload;
        },
    }
});


export const {
    fetchEventsLoading,
    fetchEvents,
    fetchEventsFailure,
    fetchEventLoading,
    fetchEvent,
    fetchEventFailure,
    setPin,
    setIsEventCodeValid,
} = eventsSlice.actions;
export default eventsSlice.reducer;
