import {useEffect} from "react";
import {TrashIcon} from "@heroicons/react/outline";
import CountrySelect from "./CountrySelect";
import Map from "../../../containers/Map";

function BasicInfoForm({
                           eventInfo,
                           setEventInfo,
                           setImage,
                           imagePreview,
                           setImagePreview,
                           type,
                           setType,
                           handleChange
                       }) {

    const typesAndColors = {
        battle: "text-battle",
        championship: "text-championship",
        party: "text-party",
        jam: "text-jam",
        workshop: "text-workshop",
        seminar: "text-seminar",
        camp: "text-camp",
        festival: "text-festival",
        performance: "text-performance"
    };

    const colorUsedTypes = (type) => {
        const elements = document.querySelectorAll(".event-type p");
        elements.forEach(element => {
            const typeName = element.textContent.toLowerCase().trim();
            if (type.includes(typeName)) {
                element.classList.add(typesAndColors[typeName]);
                element.classList.remove("text-gray-500");
            }
        });
    }

    const nullifyImage = () => {
        setImage(null);
        setImagePreview(null);
        document.querySelector("#event-image").value = "";
    }

    useEffect(() => {
        const typeToElement = {
            "battle": [".prizes", ".registration", ".entry"],
            "workshop": [".registration"],
            "openclass": [".registration"],
            "seminar": [".registration"],
            "party": [".entry"],
            "jam": [".entry"],
            "championship": [".prizes", ".registration", ".entry"],
            "festival": [".entry"],
            "performance": [".entry"]
        };

        document.querySelectorAll(".prizes, .registration, .entry").forEach(el => el.classList.add("hidden"));

        if (type) {
            Object.keys(typeToElement).forEach(key => {
                if (type.includes(key)) {
                    typeToElement[key].forEach(selector => {
                        document.querySelector(selector).classList.remove("hidden");
                    });
                }
            });
        }

        document.querySelector('#event-image').addEventListener('change', function (event) {
            const file = event.target.files[0];

            if (file) {
                const sizeLimit = 2 * 1024 * 1024; // 2MB

                if (file.size > sizeLimit) {
                    alert('File is too large. Max size is 2MB.');
                    nullifyImage();
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    setImagePreview(reader.result.split(",")[1]);
                };
                reader.readAsDataURL(file);
            }
        });

        colorUsedTypes(type);
        //eslint-disable-next-line
    }, [type]);

    const syncDateOnlyForViewPurposesItDontSetValueToEventObjectIDontKnowWhy = () => {
        if (document.querySelector(".dateEnd").value) return;
        document.querySelector(".dateEnd").value = document.querySelector(".dateStart").value;
    }

    const switchTypeColor = (e, color) => {
        e.preventDefault();
        if (type.includes(e.target.innerText)) {
            setType(type.filter(t => t !== e.target.innerText));
        } else {
            setType([...type, e.target.innerText]);
        }
        e.target.classList.toggle(color);
        e.target.classList.toggle("text-gray-500");
        e.target.classList.toggle("hover:text-gray-300");
    }

    const nextNav = (e) => {
        e.preventDefault();
        const peopleNav = document.querySelector(".create-event-people");
        const categoriesNav = document.querySelector(".create-event-categories");
        const socialsNav = document.querySelector(".create-event-socials");

        peopleNav.classList.add("bg-custom-700");
        peopleNav.classList.add("text-seminar");
        peopleNav.classList.add("border", "border-seminar");
        setTimeout(() => {
            peopleNav.classList.remove("bg-custom-700");
            categoriesNav.classList.add("border", "border-seminar");
            categoriesNav.classList.add("text-seminar");
        }, 300);

        setTimeout(() => {
            categoriesNav.classList.remove("bg-quaternary");
            socialsNav.classList.add("text-seminar");
            socialsNav.classList.add("border", "border-seminar");
        }, 600);

        setTimeout(() => {
            peopleNav.classList.remove("text-seminar");
            categoriesNav.classList.remove("text-seminar");
            socialsNav.classList.remove("text-seminar");
            peopleNav.classList.remove("border", "border-seminar");
            categoriesNav.classList.remove("border", "border-seminar");
            socialsNav.classList.remove("border", "border-seminar");
        }, 1200);
    }

    return (
        <>
            <form onChange={handleChange}>
                <div className={"create-event-basic-info-form"}>
                    <div className={"flex m-3 gap-5 h-1/2"}>
                        <div className={"flex flex-col w-1/2 gap-3"}>
                            <label htmlFor={"event-name"}
                                   className={`font-bold ${!eventInfo.name ? "text-red-300" : ""}`}>
                                Event Name
                            </label>
                            <input className={"input w-3/4"} type={"text"} id={"event-name"}
                                   name={"name"}
                                   placeholder={"Event Name"}
                                   defaultValue={eventInfo.name}
                                   style={{borderColor: !eventInfo.name ? "rgb(252, 165, 165)" : ""}}
                            />
                            <label htmlFor={"event-type"} className={"font-bold"}>Type</label>
                            <div className={"input w-3/4 event-type hover:cursor-pointer type"}>
                                {Object.keys(typesAndColors).map((type, index) => {
                                    return (
                                        <p key={index} onClick={(e) => switchTypeColor(e, typesAndColors[type])}
                                           className={`text-gray-500 hover:text-gray-300`}>{type}</p>
                                    )
                                })
                                }
                            </div>
                            <label className={"font-bold"}>Location</label>
                            <input className={"input w-3/4"} type={"text"} id={"event-location-name"} name={"spotName"}
                                   placeholder={"Location Name"}
                                   defaultValue={eventInfo.spotName}/>
                            <input className={"input w-3/4"} type={"text"} id={"event-location-street"} name={"street"}
                                   placeholder={"Street"} defaultValue={eventInfo.street}/>
                            <input className={"input w-3/4"} type={"text"} id={"event-location-streetNumber"}
                                   name={"streetNumber"}
                                   placeholder={"Street Number"}
                                   defaultValue={eventInfo.streetNumber}/>
                            <input className={"input w-3/4"} type={"text"} id={"event-location-city"} name={"city"}
                                   placeholder={"City"} defaultValue={eventInfo.city}/>
                            <input className={"input w-3/4"} type={"text"} id={"event-location-zipcode"}
                                   name={"zipCode"}
                                   placeholder={"zipCode"}
                                   defaultValue={eventInfo.zipCode}/>
                            <CountrySelect defaultValue={eventInfo.country}
                                           setEventInfo={setEventInfo}/>
                            {/*<Map/>*/}
                        </div>
                        <div className={"flex flex-col w-1/2 gap-3"}>
                            <label
                                className={`font-bold ${!eventInfo.dateStart ? "text-red-300" : ""}`}>Starts</label>
                            <div className={"grid grid-cols-2 gap-2 w-3/4"}>
                                <input className={"input dateStart"} type={"date"} id={"event-date-start"}
                                       name={"dateStart"}
                                       placeholder={"Date start"}
                                       defaultValue={eventInfo.dateStart}
                                       style={{borderColor: !eventInfo.dateStart ? "rgb(252, 165, 165)" : ""}}
                                       onChange={syncDateOnlyForViewPurposesItDontSetValueToEventObjectIDontKnowWhy}/>
                                <input className={"input"} type={"time"} id={"event-time-start"} name={"timeStart"}
                                       placeholder={"Time start"}
                                       defaultValue={eventInfo.timeStart}/>
                            </div>
                            <label className={"font-bold"}>Ends</label>
                            <div className={"grid grid-cols-2 gap-2 w-3/4"}>
                                <input className={"input dateEnd"} type={"date"} id={"event-date-end"} name={"dateEnd"}
                                       placeholder={"Date end"}
                                       defaultValue={eventInfo.dateEnd}/>
                                <input className={"input"} type={"time"} id={"event-time-end"} name={"timeEnd"}
                                       placeholder={"Time"}
                                       defaultValue={eventInfo.timeEnd}/>
                            </div>
                            <label htmlFor={"event-about"} className={"font-bold"}>About</label>
                            <textarea className={`input h-52 w-3/4`} id={"event-description"} name={"description"}
                                      placeholder={"Description"}
                                      defaultValue={eventInfo.description} maxLength={1000}/>
                            {imagePreview &&
                                <div className={"w-3/4 relative"}>
                                    <label htmlFor={"event-image"} className={"font-bold"}>Current Image</label>
                                    <TrashIcon onClick={nullifyImage}
                                               className={"img-trash h-4 w-4 absolute top-8 right-2 hover:text-red-500"}/>
                                    <img className={"rounded-md"} src={`data:image/jpeg;base64,${imagePreview}`}
                                         alt={"Event"}/>
                                </div>
                            }
                            <label htmlFor={"event-image"} className={"font-bold"}>Image (max size: 2MB)</label>
                            <input className={"input w-3/4"} type={"file"} accept={"image/*"} id={"event-image"}
                                   name={"image"}/>
                            <div className={"prizes hidden flex flex-col gap-3"}>
                                <label htmlFor={"event-prizes"} className={"font-bold"}>Prizes</label>
                                <input className={"input w-3/4"} type={"text"} id={"event-prizes"} name={"prizes"}
                                       placeholder={"Prizes"}
                                       defaultValue={eventInfo.prizes}/>
                            </div>
                            <div className={"registration hidden flex flex-col gap-3"}>
                                <label htmlFor={"event-registrationFee"} className={"font-bold"}>Registration</label>
                                <input className={"input w-3/4"} type={"text"} id={"event-registrationFee"}
                                       name={"registrationFee"}
                                       placeholder={"Registration fee"}
                                       defaultValue={eventInfo.registrationFee}/>
                            </div>
                            <div className={"entry hidden flex flex-col gap-3"}>
                                <label htmlFor={"event-entry"} className={"font-bold"}>Entry</label>
                                <input className={"input w-3/4"} type={"text"} id={"event-entry"} name={"entryFee"}
                                       placeholder={"Entry fee"}
                                       defaultValue={eventInfo.entryFee}/>
                            </div>
                            <div className={"pt-8"}>
                                <button className={"button"} onClick={nextNav}>what's next ?</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default BasicInfoForm;