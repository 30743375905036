function Filter() {
    return (
        <div className="w-200 bg-custom-900 rounded-md h-line overflow-auto hidden">
            <div className="text-center mt-3 text-xl">
                Filter
            </div>
            <p className="text-center mt-5">coming soon...</p>
        </div>
    )
}

export default Filter
