import Underscore from "../../containers/Underscore";
import {useState} from "react";
import {setPin} from "../../redux/eventsSlice";
import {useDispatch} from "react-redux";
import useEvents from "../../services/useEvents";
import {useNavigate, useParams} from "react-router-dom";

function PinModal({togglePinModal, toggleDeleteModal, option}) {
    const dispatch = useDispatch();
    const [eventPin, setEventPin] = useState("");
    const {validatePin} = useEvents();
    const id = useParams().id;
    const navigate = useNavigate();

    const toggleWrongPin = () => {
        document.querySelector(".wrongPin").classList.toggle("invisible");
        setTimeout(() => {
            document.querySelector(".wrongPin").classList.toggle("invisible");
        }, 2000);
    }

    const pinInputHandle = (event) => {
        setEventPin(event.target.value);
        if (event.key === "Enter") {
            navigateFromPin();
        }
    }

    const navigateFromPin = () => {
        dispatch(setPin(eventPin));
        validatePin(eventPin, id)
            .then(response => {
                if (response.status !== 200) {
                    toggleWrongPin();
                } else {
                    switch (option) {
                        case "delete":
                            toggleDeleteModal();
                            break;
                        case "edit":
                            navigate("/events/edit/");
                            break;
                        default:
                            console.log("no option selected");
                    }
                    togglePinModal();
                }
            });
    }

    return (
        <div className={"pinModal flex fixed inset-0 justify-center items-center hidden"}>
            <div className={"bg-custom-800 w-400 h-230 rounded-md p-6 flex flex-col justify-center items-center gap-3"}>
                <p className={"text-xl font-bold"}>Enter pin</p>
                <Underscore/>
                <input type={"password"} onKeyUp={pinInputHandle}
                       className={"w-1/2 input"}></input>
                <p className={"wrongPin invisible text-red-700"}>Wrong pin !</p>
                <div className={"flex gap-3"}>
                    <button onClick={navigateFromPin}
                            className={"button"}>Submit
                    </button>
                    <button onClick={togglePinModal}
                            className={"button"}>Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PinModal
