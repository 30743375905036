import useEvents from "../../services/useEvents";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";

function DeleteModal({toggleDeleteModal}) {
    const id = useParams().id;
    const {deleteEvent} = useEvents();
    const navigate = useNavigate();

    const handleDelete = async () => {
        await deleteEvent(id);
        toggleDeleteModal();
        navigate("/");
    }

    return (
        <div className={"deleteModal fixed flex justify-center items-center inset-0 hidden"}>
            <div className={"flex justify-center items-center flex-col bg-custom-800" +
                " w-400 h-230 rounded-md p-6 gap-3 border border-red-700"}>
                <p>Are you sure you want to delete this event?</p>
                <p><strong className={"text-red-700"}>Warning: </strong>This action cannot be undone.</p>
                <div className={"flex gap-3 mt-4"}>
                    <button className={"button"}
                    onClick={handleDelete}>Yes</button>
                    <button className={"button"}
                    onClick={toggleDeleteModal}>No</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal;
