function Mobile() {

    function isMobile() {
        const userAgent = navigator.userAgent;
        return /Mobi|Android/i.test(userAgent);
    }

    function mobileFonts() {
        const paragraphs = document.querySelectorAll("p");
        paragraphs.forEach(paragraph => {
            paragraph.style.fontSize = "calc(1rem + 1.5vh)";
        })

        const lis = document.querySelectorAll("li");
        lis.forEach(li => {
            li.style.fontSize = "calc(1rem + 1.5vh)";
        })

        const h1s = document.querySelectorAll("h1");
        h1s.forEach(h1 => {
            h1.style.fontSize = "calc(2.25rem + 1.5vh)";
        })
        const h2s = document.querySelectorAll("h2");
        h2s.forEach(h2 => {
            h2.style.fontSize = "calc(1.875rem + 1.5vh)";
            h2.style.paddingBottom = "calc(1.5rem + 1.5vh)";
        })
        const h3s = document.querySelectorAll("h3");
        h3s.forEach(h3 => {
            h3.style.fontSize = "calc(1.5rem + 1.5vh)";
            h3.style.paddingBottom = "calc(1rem + 1vh)";
        })

        const abouts = document.querySelectorAll("pre");
        abouts.forEach(about => {
            about.style.fontSize = "calc(1rem + 1.5vh)";
        });

        const labels = document.querySelectorAll("label");
        labels.forEach(label => {
            label.style.fontSize = "calc(1rem + 0.7vh)";
        })
        const buttons = document.querySelectorAll("button");
        buttons.forEach(button => {
            button.style.fontSize = "calc(1rem + 1vh)";
        })
    }

    const mobileHeader = () => {
        if (isMobile()) {
            mobileFonts();
            const hamburger = document.querySelector(".hamburger");
            const menu = document.querySelector(".menu");
            menu.classList.remove("h-70");
            menu.classList.remove("top-[74px]");
            menu.classList.add("top-[130px]");
            hamburger.classList.remove("h-10", "w-10");
            hamburger.classList.add("h-20", "w-20");
            document.querySelector("header").classList.add("h-[110px]");
        }
    }

    const mobileEvents = () => {
        if (isMobile()) {
            mobileFonts();

            document.querySelectorAll(".months").forEach(month => {
                month.classList.remove("ml-20");
                month.classList.add("ml-32");
            });
            const ue = document.querySelector(".ue");
            ue.classList.remove("h-10");
            ue.classList.add("h-[70px]");

            document.querySelectorAll(".card-date").forEach(dateDiv => {
                dateDiv.classList.remove("h-16", "w-16");
                dateDiv.classList.add("h-[150px]", "w-[150px]");
            });
            document.querySelectorAll(".card-date p").forEach(p => {
                p.classList.add("p-3");
            });
            document.querySelectorAll(".date-dash").forEach(dash => {
                dash.classList.remove("p-3");
                dash.classList.add("p-1");
            });

            document.querySelectorAll(".card-date p").forEach(date => {
                date.style.fontSize = "calc(1rem + 1vh)";
            });

            const cards = document.querySelectorAll(`[class^="card-info-"]`);
            cards.forEach(card => {
                card.classList.add("max-w-[700px]");
                card.classList.remove("ml-2");
                card.classList.add("ml-5", "mb-5");
            });

            if (cards.length > 0) {
                const lastCard = cards[cards.length - 1];
                lastCard.classList.remove("mb-5");
                lastCard.classList.add("mb-[300px]");
            }

            const verticalLine = document.querySelector(".vertical-line");
            verticalLine.classList.remove("ml-22");
            verticalLine.classList.add("ml-[75px]");

            document.querySelectorAll(".small-card").forEach(card => {
                card.classList.remove("m-3");
                card.classList.add("mx-10");
                card.classList.add("my-5");
            });

            document.querySelectorAll(".event-type span").forEach(type => {
                type.classList.add("text-4xl");
            });

            document.querySelectorAll(".today").forEach(today => {
                today.classList.remove("w-2", "h-2");
                today.classList.add("w-[15px]", "h-[15px]");
            });
        }
    }

    function mobileAbout() {
        if (isMobile()) {
            mobileFonts();
            document.querySelectorAll(".underline").forEach(underline => {
                underline.classList.remove("w-20");
                underline.classList.add("w-[150px]");
            });
            document.querySelector(".header-about").classList.add("mt-10");
            document.querySelectorAll(".level").forEach(level => {
                level.classList.add("mt-16");
            });
            document.querySelectorAll(".lvl-about").forEach(lvl => {
                lvl.classList.add("my-10");
            });
            document.querySelectorAll("li").forEach(can => {
                can.classList.add("pl-6");
            });
            document.querySelectorAll(".p-can").forEach(can => {
                can.classList.add("m-6");
            });
        }
    }

    const mobileContact = () => {
        if (isMobile()) {
            mobileFonts();
            document.querySelector(".contact-container").classList.add("h-mobile");
        }
    }

    const mobileSocials = () => {
        if (isMobile()) {
            document.querySelectorAll(".fa-icon").forEach((icon) => {
                icon.classList.add("h-10");
                icon.classList.add("w-10");
                icon.classList.add("p-5");
            })
        }
    }

    const mobileEvent = () => {
        if (isMobile()) {
            mobileFonts();
            document.querySelector(".right-panel").style.paddingBottom = "400px";

            document.querySelector(".event-name").classList.add("p-10");
            document.querySelectorAll(".info-panel").forEach(panel => {
                panel.classList.add("gap-14");
            })
            document.querySelectorAll(".gray-box").forEach(box => {
                box.style.padding = "40px";
            })

            document.querySelectorAll(".event-element").forEach(name => {
                name.classList.add("pb-4");
            })
            const fee = document.querySelector(".fee");
            fee && fee.classList.add("mt-20");

            document.querySelectorAll(".event-element-value-div").forEach(name => {
                name.classList.add("mt-4");
            })
            document.querySelectorAll(".event-element-value-name").forEach(name => {
                name.style.width = "350px";
            });
            document.querySelectorAll(".team-names").forEach(name => {
                name.classList.remove("ml-10");
                name.classList.add("ml-48");
            })
        }
    }

    const mobileDonate = () => {
        if (isMobile()) {
            mobileFonts();
            const donateH1 = document.querySelector(".donate h1");
            donateH1.classList.remove("pb-6");
            donateH1.classList.add("pb-10");
            donateH1.classList.add("pt-16");

            const donateLastLi = document.querySelector(".donate-last-li");
            donateLastLi.classList.remove("pb-10");
            donateLastLi.classList.add("pb-20");


            document.querySelectorAll(".donate-div").forEach(div => {
                div.style.width = "90%";
                div.style.paddingTop = "30px";
            })

            document.querySelectorAll(".donate p").forEach((p, index) => {
                p.classList.remove("pb-10");
                p.classList.add("pb-20");

            });

            document.querySelectorAll(".donate h3").forEach((h3, index) => {
                h3.classList.remove("pb-2");
                h3.classList.add("pb-8");
            });
        }
    }

    return {
        mobileHeader,
        mobileEvents,
        mobileAbout,
        mobileContact,
        mobileEvent,
        mobileSocials,
        mobileDonate
    }
}

export default Mobile;