import Select from "react-select";
import countryList from "react-select-country-list";
import 'flag-icons/css/flag-icons.min.css';

function CountrySelect({defaultValue, setEventInfo}) {
    const options = countryList().getData();

    const optionsWithFlags = [
        {value: "", label: 'No Country'},
        ...options.map((country) => ({
                value: country.value,
                label: (
                    <div className="flex items-center">
                        <span className={`fi fi-${country.value.toLowerCase()}`}/>
                        <span className="ml-2">{country.label}</span>
                    </div>
                )
            }
        ))
    ]

    const handleCountryChange = (selectedOption) => {
        setEventInfo(prevState => ({
            ...prevState, country: selectedOption.value
        }));
    };

    return (
        <Select options={optionsWithFlags}
                className={"w-3/4"}
                placeholder={"Country"}
                id={"event-location-country"}
                defaultValue={defaultValue}
                onChange={handleCountryChange}
                styles={{
                    control: (styles, {isFocused}) => ({
                        ...styles,
                        backgroundColor: "rgb(66,72,86)",
                        border: "none",
                        boxShadow: isFocused ? '0 0 0 1px rgb(209, 213, 219)' : '0 0 0 1px rgb(107, 114, 128)',
                        '&:hover': {borderColor: 'none'},
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: 'rgb(209, 213, 219)'
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        color: 'rgb(107, 114, 128)'
                    }),
                    menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'rgb(66,72,86)',
                        color: 'rgb(209, 213, 219)',
                    }),
                    option: (provided, {isFocused, isSelected}) => ({
                        ...provided,
                        backgroundColor: isFocused ? 'rgb(107, 114, 128)' : 'rgb(66,72,86)',
                        color: isSelected ? 'rgb(209, 213, 219)' : 'rgb(209, 213, 219)',
                        '&:hover': {backgroundColor: 'rgb(107, 114, 128)'}
                    }),
                    input: (provided) => ({
                        ...provided,
                        color: 'rgb(209, 213, 219)'
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        backgroundColor: 'rgb(107, 114, 128)' // Change color of indicator separator pipe
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        color: 'rgb(107, 114, 128)',
                        ":hover": {color: 'rgb(209, 213, 219)'}
                    }),
                }}/>
    );
}

export default CountrySelect;