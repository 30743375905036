import React from "react";

const Footer = () => {
    return (
        <footer className="h-70 w-full p-2 pt-1 hidden lg:block">
            <div className="flex justify-end items-center h-full bg-custom-800 rounded-md">
                <p className="pr-8">Created by DeenO</p>
            </div>
        </footer>
    );
};

export default Footer;