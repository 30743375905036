import React, {useCallback, useEffect, useState} from "react";
import Underscore from "../../../containers/Underscore"
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import ShowCategories from "./createCategoryComponents/ShowCategories";
import ShowStyles from "./createCategoryComponents/ShowStyles";

function CreateCategory({categories, setCategories, type}) {
    const [styles, setStyles] = useState([
        "Hip Hop",
        "House Dance",
        "Popping",
        "Locking",
        "Breaking",
        "Waacking",
        "Vogue",
        "Krump",
        "Litefeet",
        "Dancehall",
        "Afro",
        "Experimental",
        "All Styles",
    ]);
    const [vs, setVs] = useState([
        "1 vs 1",
        "2 vs 2",
        "3 vs 3",
        "4 vs 4",
        "5 vs 5",
        "Crew vs Crew",
    ]);
    const [age, setAge] = useState([
        "Kids (12 & under)",
        "Juniors (13-16)",
        "Adults (17-30)",
        "OG's (31-40)",
        "Masters (41+)",
        "all ages",
    ]);
    const [category, setCategory] = useState({
        age: "",
        vs: "",
        styles: [],
    });
    const [newAge, setNewAge] = useState("");
    const [newVs, setNewVs] = useState("");
    const [newStyle, setNewStyle] = useState("");

    const [visibility, setVisibility] = useState({
        ages: "hidden",
        styles: "hidden",
        vs: "hidden",
    });

    const blue = "white";

    const toggleElementDropdown = useCallback((element) => {
        document.querySelector(`.${element}-dropdown`).classList.toggle("hidden")
        document.querySelector(`.${element}-selected`).classList.toggle("hidden")
    }, []);


    const handleClickOutsideElement = useCallback((e, element) => {
        if (!e.target.closest(`.${element}`)) {
            const dropdown = document.querySelector(`.${element}-dropdown`);
            const elementSelected = document.querySelector(`.${element}-selected`);
            dropdown && dropdown.classList.add('hidden');
            elementSelected && elementSelected.classList.remove('hidden');
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const elements = ["vs", "styles", "age"];
    const addEventListeners = useCallback(() => {
        elements.forEach(element => {
            document.addEventListener('mouseup', (e) =>
                handleClickOutsideElement(e, element));
        })
    }, [elements, handleClickOutsideElement]);

    const removeEventListeners = useCallback(() => {
        elements.forEach(element => {
            document.removeEventListener('mouseup', (e) =>
                handleClickOutsideElement(e, element));
        })
    }, [elements, handleClickOutsideElement]);

    const selectValueElement = (n) => {
        return document.querySelector(`p[data-value="${n}"]`);
    }

    const toggleColor = useCallback((values, key) => {
            if (key !== "styles") {
                values.forEach(n => {
                    const element = selectValueElement(n);
                    if (category[key] === n) {
                        element.style.color = blue;
                    } else {
                        element.style.color = "";
                    }
                })
            } else {
                values.forEach(n => {
                    const element = selectValueElement(n);
                    if (category[key].includes(n)) {
                        element.style.color = blue;
                    } else {
                        element.style.color = "";
                    }
                })
            }
        }, [category]
    );

    useEffect(() => {
        const typeToElement = {
            "battle": ["ages", "styles", "vs"],
            "championship": ["ages", "styles", "vs"],
            "workshop": ["styles"],
            "openclass": ["styles"],
            "seminar": ["styles"],
            "party": ["styles"],
            "jam": ["styles"],
            "camp": ["styles"],
            "performance": ["styles"]
        };

        const newVisibility = {
            ages: "hidden",
            styles: "hidden",
            vs: "hidden",
        };


        if (type) {
            Object.keys(typeToElement).forEach(key => {
                if (type.includes(key)) {
                    typeToElement[key].forEach(element => {
                        newVisibility[element] = "visible";
                    });
                }
            });
        }

        setVisibility(newVisibility);

        toggleColor(vs, "vs");
        toggleColor(styles, "styles");
        toggleColor(age, "age");

        addEventListeners();
        return () => {
            removeEventListeners();
        }

        //eslint-disable-next-line
    }, [type, vs, styles, age, toggleColor, categories]);


    const handleCategoryChange = (name, value) => {
        if (value === "") return;

        if (name !== "styles") {
            if (category[name].includes(value)) {
                setCategory({...category, [name]: ""});
                return;
            }
            setCategory({...category, [name]: value});
        } else {
            if (category.styles.includes(value)) {
                setCategory({...category, styles: category.styles.filter((style) => style !== value)})
                return;
            }
            setCategory({...category, [name]: [...category.styles, value]});
        }
    }

    const handleNewSubmit = (e, name, state, newElement, callback) => {
        e.preventDefault();
        if (newElement === "") return;
        callback([...state, newElement]);
        handleCategoryChange(name, newElement);
        document.querySelector(`input[name="${name}"]`).value = "";
    }

    const newVsSubmit = (e) => handleNewSubmit(e, "vs", vs, newVs, setVs);
    const newStyleSubmit = (e) => handleNewSubmit(e, "styles", styles, newStyle, setStyles);
    const newAgeSubmit = (e) => handleNewSubmit(e, "age", age, newAge, setAge);

    const handleKeyPress = (e, submitCallback) => {
        e.preventDefault();
        if (e.key === "Enter") {
            submitCallback(e);
        }
    }

    const addCategory = (e) => {
        e.preventDefault();
        if (category.vs === "" && category.age === "" && category.styles.length === 0) return;
        setCategories([...categories, category]);
        setCategory({
            age: "",
            vs: "",
            styles: [],
        });
    }


    const mapElement = (element, name) => {
        return element.map((n, index) => {
            return (
                <p key={index}
                   onClick={() => handleCategoryChange(name, n)}
                   data-value={n}
                   id={`${name}${index}`}
                   className={"hover:bg-custom-700 rounded-md p-1 text-gray-500"}>{n}</p>
            )
        })
    }

    const elementStyle = "bg-custom-750 h-fit rounded-md border border-custom-600 w-1/3 p-3"

    return (
        <div className={"create-event-categories-form hidden"}>
            <div className={"flex gap-2"}>
                <div className={`vs ${elementStyle} ${visibility.vs}`}>
                    <div onClick={() => toggleElementDropdown("vs")}>
                        <div className={"flex justify-between"}>
                            <p className={"font-bold"}>VS</p>
                            <div>
                                <ChevronUpIcon className={"h-3"}/>
                                <ChevronDownIcon className={"h-3"}/>
                            </div>
                        </div>
                        <Underscore/>
                        <p className={"vs-selected text-gray-200"}>{category.vs}</p>
                    </div>
                    <div className={"vs-dropdown hidden"}>
                        <div className={"pb-4"}>
                            {mapElement(vs, "vs")}
                        </div>
                        <input name={"vs"}
                               placeholder={"create yours"}
                               onChange={(e) => setNewVs(e.target.value)}
                               onKeyUp={(e) => handleKeyPress(e, newVsSubmit)}
                               className={"input w-3/4"}/>
                        <button onClick={(e) => newVsSubmit(e)}
                                className={`button ml-3`}
                                style={{width: "fit-content"}}>+
                        </button>
                    </div>
                </div>

                <div className={`styles ${elementStyle} ${visibility.styles}`}>
                    <div onClick={() => toggleElementDropdown("styles")}>
                        <div className={"flex justify-between"}>
                            <p className={"font-bold"}>Styles</p>
                            <div>
                                <ChevronUpIcon className={"h-3"}/>
                                <ChevronDownIcon className={"h-3"}/>
                            </div>
                        </div>
                        <Underscore/>
                        <div className={"styles-selected"}>
                            {category.styles.map(style => (
                                <p key={style} className={"text-gray-200"}>{style}</p>
                            ))}
                        </div>
                    </div>
                    <div className={"styles-dropdown hidden"}>
                        <div className={"pb-4"}>
                            {mapElement(styles, "styles")}
                        </div>
                        <input name={"styles"}
                               placeholder={"create yours"}
                               onChange={(e) => setNewStyle(e.target.value)}
                               onKeyUp={(e) => handleKeyPress(e, newStyleSubmit)}
                               className={"input w-3/4"}
                        />
                        <button onClick={(e) => newStyleSubmit(e)}
                                className={`button ml-3`}
                                style={{width: "fit-content"}}>+
                        </button>

                    </div>
                </div>

                <div className={`age ${elementStyle} ${visibility.ages}`}>
                    <div onClick={() => toggleElementDropdown("age")}>
                        <div className={"flex justify-between"}>
                            <p className={"font-bold"}>Age</p>
                            <div>
                                <ChevronUpIcon className={"h-3"}/>
                                <ChevronDownIcon className={"h-3"}/>
                            </div>
                        </div>
                        <Underscore/>
                        <p className={"age-selected text-gray-200"}>{category.age}</p>
                    </div>
                    <div className={"age-dropdown hidden"}>
                        <div className={"pb-4"}>
                            {mapElement(age, "age")}
                        </div>
                        <div>
                            <input name={"age"}
                                   placeholder={"create yours"}
                                   onChange={(e) => setNewAge(e.target.value)}
                                   onKeyUp={(e) => handleKeyPress(e, newAgeSubmit)}
                                   className={"input w-3/4"}/>
                            <button onClick={e => newAgeSubmit(e)}
                                    className={`button ml-3`}
                                    style={{width: "fit-content"}}>+
                            </button>
                        </div>
                    </div>
                </div>

                <button onClick={addCategory}
                        className={`button font-bold max-h-[64px]`}>{type.includes("battle") ? "Add Category" : "Add Styles"}
                </button>
            </div>
            <div className={"mt-2 grid grid-cols-4 rounded-md "}>
                {categories.map((c, id) => {
                    return (
                        c.vs || c.age ?
                            <ShowCategories category={c} categories={categories} key={id} id={id}
                                            setCategories={setCategories}/>
                            :
                            <ShowStyles category={c} categories={categories} key={id} id={id}
                                        setCategories={setCategories}/>
                    )
                })}
            </div>
        </div>
    )
}

export default CreateCategory;