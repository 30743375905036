import PeopleModel from "./PeopleModel";
import React, {useEffect, useState} from "react";

function PeopleForm({
                        organizers, setOrganizers,
                        judges, setJudges,
                        lecturers, setLecturers,
                        battleGuests, setBattleGuests,
                        mcs, setMcs,
                        djs, setDjs,
                        sponsors, setSponsors,
                        type
                    }) {

    const [visibility, setVisibility] = useState({
        organizers: false,
        judges: false,
        lecturers: false,
        battleGuests: false,
        mcs: false,
        djs: false,
        sponsors: false
    });

    useEffect(() => {
        const typeToElement = {
            "battle": ["organizers", "judges", "mcs", "djs", "battleGuests"],
            "workshop": ["organizers", "lecturers"],
            "openclass": ["organizers", "lecturers"],
            "seminar": ["organizers", "lecturers"],
            "party": ["organizers", "mcs", "djs"],
            "jam": ["organizers", "djs"],
            "camp": ["organizers", "mcs", "djs", "lecturers"],
            "championship": ["organizers", "judges", "mcs"],
            "festival": ["organizers", "mcs", "djs"],
            "performance": ["organizers"],
        };

        const newVisibility = {
            organizers: false,
            judges: false,
            lecturers: false,
            battleGuests: false,
            mcs: false,
            djs: false,
            sponsors: false,
        };

        if (type) {
            Object.keys(typeToElement).forEach(key => {
                if (type.includes(key)) {
                    typeToElement[key].forEach(element => {
                        newVisibility[element] = true;
                    });
                }
            });
        }

        setVisibility(newVisibility);
    }, [type]);


    return (
        <div className={"create-event-people-form hidden"}>

            {visibility.organizers &&
                <PeopleModel id={"event-organizers"} title={"Organizers"} name={"Organizer"}
                             people={organizers} setPeople={setOrganizers}/>}

            {visibility.judges &&
                <PeopleModel id={"event-judges"} title={"Judges"} name={"Judge"} people={judges}
                             setPeople={setJudges}/>}

            {visibility.lecturers &&
                <PeopleModel id={"event-lecturers"} title={"Lecturers"} name={"Lecturer"} people={lecturers}
                             setPeople={setLecturers}/>}

            {visibility.battleGuests &&
                <PeopleModel id={"event-battle-guests"} title={"Battle Guests"} name={"Battle Guest"}
                             people={battleGuests}
                             setPeople={setBattleGuests}/>}

            {visibility.mcs &&
                <PeopleModel id={"event-mcs"} title={"Mcs"} name={"Mc"} people={mcs}
                             setPeople={setMcs}/>}

            {visibility.djs &&
                <PeopleModel id={"event-djs"} title={"Djs"} name={"Dj"} people={djs}
                             setPeople={setDjs}/>}

            {visibility.sponsors &&
                <PeopleModel id={"event-sponsors"} title={"Sponsors"} name={"Sponsor"} people={sponsors}
                             setPeople={setSponsors}/>}

        </div>
    )
}

export default PeopleForm;