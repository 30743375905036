import {TrashIcon} from "@heroicons/react/outline";
import React from "react";

function ShowCategories({category, categories, id, setCategories}) {

    const removeCategory = (e, id) => {
        e.preventDefault()
        setCategories(categories.filter((category, index) => index !== id));
    }

    const toggleTrashIcon = (e) => {
        e.currentTarget.querySelector(".button-category").classList.toggle("hidden")
    }

    return (
        <div className={"created-category bg-custom-600 p-3 m-1 rounded-md " +
            "relative flex flex-col justify-center items-center border border-gray-600"}
             key={id}
             onPointerEnter={toggleTrashIcon}
             onPointerLeave={toggleTrashIcon}
        >
            {category.styles.map((style, index) => {
                return <p key={index} className={"text-primary"}>- {style} -</p>
            })}
            <div className={"w-1/5 "}></div>
            <div>
                <button onClick={(e) => removeCategory(e, id)}
                        className={"button-category absolute top-0 right-0 text-gray-500 p-2 hover:text-red-500 hidden"}
                        title={"Delete"}>
                    <TrashIcon className={"h-4"}/>
                </button>
            </div>
        </div>
    )
}

export default ShowCategories;