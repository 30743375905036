import React, {useEffect} from "react";
import bg from "../assets/images/about.png";
import Mobile from "../containers/Mobile";

const About = () => {
    const {mobileAbout} = Mobile();

    useEffect(() => {
        document.querySelectorAll(".about-container li").forEach(container => {
            container.classList.add("ml-6");
        });
        mobileAbout();
    }, [mobileAbout]);

    const underline = "underline w-20 border border-secondary ml-6 mb-2";
    const can = "p-can font-bold m-2"

    return (
        <div className={"about-container h-full p-10 overflow-auto items-center bg-cover rounded-md"}
             style={{backgroundImage: `url(${bg})`}}>
            <h2 className={"header-about text-secondary text-3xl font-bold pl-4"}>Welcome to Spoteer</h2>
            <br/>
            <p className={"lg:w-5/6 indent-6 "}>
                Welcome to Spoteer, the app designed exclusively for dancers like you.
                Say goodbye to missed events and endless searching, as Spoteer brings all dance events
                to your fingertips in one convenient hub. With every update, we're shaping the purpose, so stay tuned.
            </p>
            <br/>
            <br/>

            <div className={""}>
                <h3 className={"level"}>Level 1</h3>
                <div className={`${underline}`}></div>
                <p className={"lvl-about ml-2"}>Here, you'll find all dance events in one convenient hub,
                    empowering you to choose where to go or support effortlessly.</p>
                <br/>
                <div>
                    <div className={"about-can"}>
                        <p className={`${can}`}>Dancers can:</p>
                        <li>Browse dance event details or visit organizers' social media profiles via
                            provided links.
                        </li>
                        <p className={`${can}`}>Organizers can:</p>
                        <li>Create and edit events, making it convenient for everyone to access.
                        </li>
                    </div>
                    <br/>
                    <div className={"ml-6"}>
                        <p className={"p-can text-secondary"}>1.1</p>
                        <p>- Introduce filters for cities, styles, or other event aspects you're interested in.</p><br/>
                        <p className={"p-can text-secondary"}>1.2</p>
                        <p>- Implement maps for enhanced event accessibility.</p></div>
                </div>
                <br/>
                <br/>

                <h3 className={"level"}>Level 2</h3>
                <div className={`${underline}`}></div>
                <p className={"lvl-about ml-2"}>Let's take it up a notch and introduce user profiles</p>
                <br/>
                <div className={"about-can"}>
                    <p className={`${can}`}>Dancers and organizers can: </p>
                    <li>Create, edit, and delete profiles.</li>
                    <li>Add events to favorites.</li>
                    <li>Showcase themselves on their profile page.</li>
                    <li>Explore others' profiles and see their interests in events.</li>
                </div>
                <br/>
                <br/>

                <h3 className={"level"}>Level 3</h3>
                <div className={`${underline}`}></div>
                <p className={"lvl-about ml-2"}>This update streamlines registration for organizers</p>
                <br/>
                <div className={"about-can"}>
                    <p className={`${can}`}>Dancers can:</p>
                    <li>Register for events</li>
                    <li>Pay registration fees securely via Payment Gateway (possibly in future
                        updates).
                    </li>
                    <p className={`${can}`}>Organizers can:</p>
                    <li>Easily download lists of registered dancers for efficient event
                        management.
                    </li>
                </div>
                <br/>
                <br/>

                <h3 className={"level"}>Level 4</h3>
                <div className={`${underline}`}></div>
                <p className={"lvl-about ml-2"}>This update brings a focus on judges, eliminating paper-based
                    processes</p>
                <br/>
                <div className={"about-can"}>
                    <p className={`${can}`}>Dancers can:</p>
                    <li>Access live results post-performance.</li>
                    <li>Choose to keep results public or private based on personal preference.</li>
                    <li>View current preselection standings and others' points if public.</li>
                    <li>Add results to personal profiles.</li>
                    <p className={`${can}`}>Organizers can:</p>
                    <li>Assign judges to events.</li>
                    <li>Access live results.</li>
                    <li>View standings by points.</li>
                    <li>See top placements in tournament brackets.</li>
                    <li>Complete tournament brackets manually using judges' results.</li>
                    <p className={`${can}`}>Judges can:</p>
                    <li>Input and edit results directly into the system.</li>
                </div>
                <br/>
                <br/>

                <h3 className={"level"}>Level 5</h3>
                <div className={`${underline}`}></div>
                <p className={"lvl-about ml-2"}>This update ensures inclusivity for all</p>
                <br/>
                <div className={"about-can"}>
                    <p className={`${can}`}>Everyone can:</p>
                    <li> See live results, placements, actual bracket placements, and
                        final results of every public event.
                    </li>
                    <li>View the results history of every public event.</li>
                </div>
                <br/>
                <br/>

                <h3 className={"level"}>Level 6</h3>
                <div className={`${underline}`}></div>
                <p className={"lvl-about ml-2"}>Ever wondered who's the best battle dancer in town? Now you can find
                    out</p>
                <br/>
                <div className={"about-can"}>
                    <p className={`${can}`}>Everyone can:</p>
                    <li>View rankings of dancers.</li>
                    <li>Filter rankings by age, style, town, and more.</li>
                </div>
                <br/><br/><br/>
            </div>

            <h3 className={"font-bold text-2xl text-secondary ml-6"}>Outro</h3>
            <br/>
            <p className={"lg:w-5/6 indent-6"}>With the plan in place, Spoteer is poised to enhance dance events,
                streamlining processes and adding excitement to the game. However, building
                this platform requires significant time and effort. Your support is
                invaluable. Consider <span className={"text-secondary"}><a href={"./donate"}>donating</a></span> to help bring this vision to life.
                <br/>
                <br/>
                <strong>Thank you for your support!</strong>
            </p>
        </div>
    );
};

export default About;
