import React from "react";
import Menu from "./Menu";
import Main from "./Main";

const Body = () => {
    return (
        <>
         <div className="flex lg:h-mid px-2 py-1">
             <Menu/>
             <Main/>
         </div>
        </>
    );
};

export default Body;
