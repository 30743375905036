export const convertStringYMDToDate = (date) => {
    if (date === null || date === undefined) {
        return;
    }
    const parts = date.split("-");
    return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
}

const removeLeadingZeros = (part) => part.startsWith("0") ? part.substring(1) : part;

export const convertDateForDisplay = (dateStart, dateEnd) => {
    const partsStart = dateStart.toString().split("-").map(removeLeadingZeros);
    const [yearStart, monthStart, dayStart] = partsStart;

    const partsEnd = dateEnd.toString().split("-").map(removeLeadingZeros);
    const [yearEnd, monthEnd, dayEnd] = partsEnd;
    return [dayStart, monthStart, yearStart, dayEnd, monthEnd, yearEnd];
};

export function showTeam(eventElement, single, plural) {
    return (
        <div className={`team-container flex w-full ${!eventElement[0] && `hidden`}`}>
            <div className="event-element-value-name">
                <p className={`${eventElement.length < 2 ? "" : "hidden"}`}>{single}:</p>
                <p className={`${eventElement.length > 1 ? "" : "hidden"}`}>{plural}:</p>
            </div>
            <div className="w-fit">
                {eventElement.map((post, index) => {
                    return <p key={index}> {post.username ? post.username : post}</p>
                })}
            </div>
        </div>
    )
}

export const clearEventForm = () => {
    document.querySelectorAll("input").forEach(input => input.value = "");
    document.querySelectorAll("textarea").forEach(input => input.value = "");
}

export const currentDate = () => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
    });
    return (
        <p>
            {formattedDate}
        </p>
    );
};



