import {BrowserRouter as Router} from "react-router-dom";
import Header from "./components/common/Header.js";
import Body from "./components/common/Body";
import Footer from "./components/common/Footer";

function App() {
    return (
        <div>
            <Router>
                <div>
                    <Header/>
                    <Body/>
                    <Footer/>
                </div>
            </Router>
        </div>
    );
}

export default App;
