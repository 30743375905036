import {Route, Routes} from "react-router-dom";
import About from "../../pages/About";
import Contact from "../../pages/Contact";
import ErrorPage from "../../pages/ErrorPage";
import CreateEvent from "../events/CreateEvent";
import Events from "../events/Events";
import EditEvent from "../events/EditEvent";
import ProfileSettingsContainer from "../dancers/profileSettings/ProfileSettingsContainer";
import {useSelector} from "react-redux";
import Profile from "../dancers/Profile";
import DisplayEvent from "../events/DisplayEvent";
import Donate from "../../pages/Donate";


const Main = () => {
    const dancer = useSelector(state => state.user.currentUser);

    return (
        <div className="bg-custom-800 w-full h-full lg:ml-2 rounded-md ">
            <Routes>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/" element={<Events/>}/>
                <Route path="/create-event" element={<CreateEvent/>}/>
                <Route path={`/events/edit`} element={<EditEvent/>}/>
                <Route path={`/events/:id`} element={<DisplayEvent/>}/>
                <Route path={`/settings/${dancer.email}`} element={<ProfileSettingsContainer/>}/>
                <Route path={`/profile/${dancer.email}`} element={<Profile/>}/>
                <Route path="/*" element={<ErrorPage/>}/>
                <Route path={"/donate"} element={<Donate/>}/>
            </Routes>
        </div>
    );
};

export default Main;
