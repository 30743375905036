import hundred from "../assets/donate/100.JPG";
import fiveHundred from "../assets/donate/500.JPG";
import thousand from "../assets/donate/1000.JPG";
import {useEffect} from "react";
import hdf from "../assets/images/5.png";
import Mobile from "../containers/Mobile";

function Donate() {
    const {mobileDonate} = Mobile();


    useEffect(() => {
        mobileDonate();

        document.querySelectorAll(".donate p").forEach((p, index) => {
            p.classList.add("lg:w-5/6", "indent-6", "pb-10");

        });
        document.querySelectorAll(".donate h3").forEach((h3, index) => {
            h3.classList.add("level", "pb-2");
        });

        //eslint-disable-next-line
    }, []);

    return (
        <div className={"donate h-full p-10 overflow-auto items-center bg-cover rounded-md"}
             style={{backgroundImage: `url(${hdf})`}}>
            <h1 className={"text-secondary text-3xl font-bold pl-4 pb-6"}>Support Our Page!</h1>

            <p>
                To help keep this page alive and thriving, we need your support. Your contribution will be
                instrumental
                in maintaining and further developing this site to provide valuable content to our community.
                Every
                donation, no matter how small, makes a difference and allows us to continue our mission.
            </p>

            <h3>How Your Donation Helps:</h3>

            <li>Ensuring the ongoing maintenance and upkeep of the website.</li>
            <li>Investing in the development of new features and improvements.</li>
            <li className={"donate-last-li pb-10"}>Expanding our reach to serve even more people.</li>


            <h3>Why Donate?</h3>
            <p>
                Your generosity directly impacts the quality and sustainability of this platform. By donating, you
                become a vital part of our journey towards making a positive difference in the lives of our users.
            </p>

            <h2 className={"level pb-14"}>Ways to Donate:</h2>

            <div className={"flex flex-col lg:flex-row justify-around items-center pb-10 gap-16"}>
                <div className={"donate-div"}>
                    <img src={hundred} alt={"100"} className={"pt-4 h-[90%]"}/>
                </div>
                <div className={"donate-div"}>
                    <img src={fiveHundred} alt={"500"} className={"pt-4 h-[90%]"}/>
                </div>
                <div className={"donate-div"}>
                    <img src={thousand} alt={"1000"} className={"pt-4 h-[90%]"}/>
                </div>
            </div>

            <p className={"text-center"}>Tip: Scan this from your mobile app. <br/> <span
                className={"text-yellow-600"}>Feel free to customize the amount to fit your good mood!</span>
            </p>

            <h3>Thank You!</h3>
            <p>
                We are deeply grateful for your support and belief in our vision. Together, we can create a better
                online experience for everyone. Thank you for helping us keep this page alive and thriving!
            </p>
        </div>
    );
}

export default Donate;