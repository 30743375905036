import React from "react";

function SocialsForm({createUpdateEvent, event, props, handleChange}) {
    return (
        <div className={"create-event-socials-form hidden"}>
            <form onChange={handleChange}>
                <div className={"flex"}>
                    <div className={"w-1/2 flex flex-col p-2"}>
                        <label htmlFor={"event-tiktok"} className={"font-bold"}>Tiktok</label>
                        <input type={"text"} id={"event-tiktok"} name={"tiktok"} placeholder={"Tiktok"}
                               defaultValue={event.tiktok}
                               className={"input"}/>
                        <label htmlFor={"event-instagram"} className={"font-bold"}>Instagram</label>
                        <input type={"text"} id={"event-instagram"} name={"instagram"} placeholder={"Instagram"}
                               defaultValue={event.instagram} className={"input"}/>
                        <label htmlFor={"event-facebook"} className={"font-bold"}>Facebook</label>
                        <input type={"text"} id={"event-facebook"} name={"facebook"} placeholder={"Facebook"}
                               defaultValue={event.facebook} className={"input"}/>
                        <label htmlFor={"event-youtube"} className={"font-bold"}>Youtube</label>
                        <input type={"text"} id={"event-youtube"} name={"youtube"} placeholder={"Youtube"}
                               defaultValue={event.youtube} className={"input"}/>
                    </div>
                    <div className={"w-1/2 flex flex-col p-2"}>
                        <label htmlFor={"event-website"} className={"font-bold"}>Website</label>
                        <input type={"text"} id={"event-website"} name={"website"} placeholder={"Website"}
                               defaultValue={event.website} className={"input"}/>
                        <label htmlFor={"event-merch"} className={"font-bold"}>Merch</label>
                        <input type={"text"} id={"event-merch"} name={"merch"} placeholder={"Merch"}
                               defaultValue={event.merch} className={"input"}/>
                        <label htmlFor={"event-tickets"} className={"font-bold"}>Tickets</label>
                        <input type={"text"} id={"event-tickets"} name={"tickets"} placeholder={"Tickets"}
                               defaultValue={event.tickets} className={"input"}/>
                        <label htmlFor={"event-donate"} className={"font-bold"}>Donate</label>
                        <input type={"text"} id={"event-donate"} name={"donate"} placeholder={"Donate"}
                               defaultValue={event.donate} className={"input"}/>
                    </div>
                </div>
                <div className={"flex justify-center items-center flex-col"}>
                    <div className={`flex flex-col mt-4 ${props.button === "Update" ? "hidden" : ""}`}>
                        <label htmlFor={"pin"}
                               className={`font-bold ${!event.pin || event.pin.length < 4 ? "text-red-300" : ""}`}>Create
                            a 4-digit PIN for editing this event.</label>
                        <input type={"password"} maxLength={4} name={"pin"} className={"input text-center "}
                               style={{borderColor: !event.pin ? "rgb(252, 165, 165)" : ""}}/>
                    </div>
                    <button onClick={createUpdateEvent} className={"button mt-4"}>{props.button}</button>
                </div>
            </form>
        </div>
    )
}

export default SocialsForm;