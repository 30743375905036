import {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import useEvents from "../../services/useEvents";
import {useDispatch, useSelector} from "react-redux";
import {fetchEvent} from "../../redux/eventsSlice";
import {convertStringYMDToDate, currentDate} from "../../containers/Helpers";
import Mobile from "../../containers/Mobile";
import Filter from "./Filter";
import EventDateOnTimeline from "./EventDateOnTimeline";
import bg3 from "../../assets/images/7.png";

const Events = () => {
    const {events, eventsLoading, eventsError} = useSelector((state) => state.events);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getEvents} = useEvents();
    const {mobileEvents} = Mobile();

    setTimeout(() => {
        mobileEvents();
    }, 1);

    useEffect(() => {
        getEvents().then(() => {
            dispatch(fetchEvent({}));
        });
        // eslint-disable-next-line
    }, []);


    let previousDate = "";
    const isDateRepeated = (startDate, endDate) => {
        if (startDate === previousDate && startDate === endDate) {
            return true
        } else {
            previousDate = startDate;
            return false;
        }
    }

    const showFullEvent = (id) => {
        navigate(`/events/${id}`);
    }

    let lastUsedMonth = "";
    const showMonth = (date) => {
        const month = convertStringYMDToDate(date).toDateString().substring(4, 7);
        const year = convertStringYMDToDate(date).toDateString().substring(11, 15);
        const monthStyle = "text-primary font-bold ml-20 my-4 months";

        if (lastUsedMonth !== month) {
            lastUsedMonth = month;
            switch (month) {
                case("Jan"):
                    return <p className={`${monthStyle}`}> January {year}</p>
                case("Feb"):
                    return <p className={`${monthStyle}`}> February {year}</p>
                case("Mar"):
                    return <p className={`${monthStyle}`}> March {year}</p>
                case("Apr"):
                    return <p className={`${monthStyle}`}> April {year}</p>
                case("May"):
                    return <p className={`${monthStyle}`}> May {year}</p>
                case("Jun"):
                    return <p className={`${monthStyle}`}> June {year}</p>
                case("Jul"):
                    return <p className={`${monthStyle}`}> July {year}</p>
                case("Aug"):
                    return <p className={`${monthStyle}`}> August {year}</p>
                case("Sep"):
                    return <p className={`${monthStyle}`}> September {year}</p>
                case("Oct"):
                    return <p className={`${monthStyle}`}> October {year}</p>
                case("Nov"):
                    return <p className={`${monthStyle}`}> November {year}</p>
                case("Dec"):
                    return <p className={`${monthStyle}`}> December {year}</p>
                default:
                    return <p className={`${monthStyle}`}>{month} {year}</p>
            }
        }
    }


    return (
        <div className={"events-container p-4 h-mobile lg:h-full bg-cover rounded-md"}
             style={{backgroundImage: `url(${bg3})`}}>
            <div className="ue bg-custom-600 h-10 rounded-md flex items-center justify-between">
                <p className="ml-4 text-2xl font-bold">Upcoming Events</p>
                <div className={"text-primary text-lg font-bold pr-4"}>
                    {currentDate()}
                </div>
            </div>
            <div className="mt-4 flex justify-between h-line">
                <div className="cards w-full overflow-auto">
                    <div className={"vertical-line w-px border  " +
                        "border-primary h-line fixed ml-[32px] z-0"}>
                    </div>
                    {eventsError ? <p className={"ml-28 mt-4 text-red-800"}>Error: {eventsError}</p> :
                        events && events.length === 0 && eventsLoading &&
                        <p className={"ml-28 mt-4 animate-pulse"}>events loading ...</p>}

                    {events && Array.isArray(events) ? (events.map((event, index) => {
                        return (
                            <div key={index}>
                                <div className={`${index === 0 ? "-mt-4" : ""}`}>
                                    {showMonth(event.dateStart)}
                                </div>
                                <div className="flex mb-2" key={event.id}>
                                    <div
                                        className={`${isDateRepeated(event.dateStart, event.dateEnd) ? 'invisible' : ""} relative`}>
                                        <EventDateOnTimeline dateStart={event.dateStart} dateEnd={event.dateEnd}
                                                             index={index}/>
                                    </div>
                                    <div
                                        className={`card-info-${index} flex items-center justify-between 
                                                bg-gradient-to-r from-custom-700 to-custom-750 
                                                ml-2 mr-3 rounded-md w-fit hover:from-custom-600 hover:to-custom-650 
                                                hover:cursor-pointer`}
                                        onClick={() => showFullEvent(event.id)}>
                                        <div className="small-card m-3 w-full">
                                            <div className="lg:flex justify-between">
                                                <div className="lg:flex">
                                                    <p className="ml-2 font-bold overflow-auto">{event.name}</p>
                                                    <p className="ml-2 lg:ml-6 text-secondary">{event.city}
                                                        {event.country && (event.country.length === 2 && event.country !== "CZ") ?
                                                            <span
                                                                className={`fi fi-${event.country.toLowerCase()} ml-6`}/> : ""}</p>
                                                </div>
                                            </div>
                                            <div className={"event-type w-full flex flex-wrap"}>
                                                {event.type.map((type, index) => {
                                                    switch (type) {
                                                        case "camp":
                                                            return <span key={index}
                                                                         className={"text-camp ml-3"}>#camp</span>
                                                        case "battle":
                                                            return <span key={index}
                                                                         className={"text-battle ml-3"}>#battle</span>
                                                        case "workshop":
                                                            return <span
                                                                key={index}
                                                                className={"text-workshop ml-3"}>#workshop</span>
                                                        // case "openclass":
                                                        //     return <span
                                                        //         key={index}
                                                        //         className={"text-emerald-600 ml-3"}>#openclass</span>
                                                        case "party":
                                                            return <span
                                                                key={index}
                                                                className={"text-party ml-3"}>#party</span>
                                                        case "jam":
                                                            return <span
                                                                key={index}
                                                                className={"text-jam ml-3"}>#jam</span>
                                                        case "seminar":
                                                            return <span
                                                                key={index}
                                                                className={"text-seminar ml-3"}>#seminar</span>
                                                        case "championship":
                                                            return <span
                                                                key={index}
                                                                className={"text-championship ml-3"}>#championship</span>
                                                        case "festival":
                                                            return <span
                                                                key={index}
                                                                className={"text-festival ml-3"}>#festival</span>
                                                        default:
                                                            return <span
                                                                key={index}
                                                                className={"text-primary ml-3"}>#event</span>
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })) : <div className={"ml-14 mt-4 text-yellow-600 text-xl"}>No events</div>
                    }
                </div>
                <Filter/>
            </div>
        </div>
    );
}

export default Events;