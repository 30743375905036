import {useCallback, useEffect, useState} from 'react';
import {
    TrashIcon,
    PencilIcon
} from "@heroicons/react/outline";
import PinModal from "./PinModal";
import DeleteModal from "./DeleteModal";


const EventSettingsDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [option, setOption] = useState("");

    const toggleOptionsDropdown = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);


    const handleClickOutsideOptions = useCallback((event) => {
        if (!event.target.closest('.dropdown-container')) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideOptions);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideOptions);
        }
    }, [handleClickOutsideOptions]);

    const togglePinModal = useCallback(() => {
        document.querySelector('.pinModal').classList.toggle('hidden');
    }, []);

    const toggleDeleteModal = useCallback(() => {
        document.querySelector('.deleteModal').classList.toggle('hidden');
    }, []);


    return (
        <div>
            <button onClick={toggleOptionsDropdown} className={"ml-4"}>...</button>
            <div className={`dropdown-container ${isOpen ? "" : "hidden"}`}>
                <div className={"w-4 h-4 fixed bg-custom-900 transform rotate-45 mt-1 ml-4"}></div>
                <div className={"bg-custom-900 fixed p-4 flex flex-col gap-3 rounded-md mt-2 -ml-16"}>
                    <div onClick={() => {
                        toggleOptionsDropdown();
                        togglePinModal();
                        setOption("edit");
                    }} className={"flex gap-3 hover:text-primary hover:cursor-pointer"}>
                        <PencilIcon className={"w-6 h-6"}/>
                        <p>Edit</p>
                    </div>
                    <div onClick={() => {
                        toggleOptionsDropdown();
                        togglePinModal();
                        setOption("delete");
                    }} className={"flex gap-3 hover:text-primary hover:cursor-pointer"}>
                        <TrashIcon className={"w-6 h-6"}/>
                        <p>Delete</p>
                    </div>
                </div>
            </div>
            <PinModal togglePinModal={togglePinModal} toggleDeleteModal={toggleDeleteModal} option={option}/>
            <DeleteModal toggleDeleteModal={toggleDeleteModal}/>
        </div>
    )
}

export default EventSettingsDropdown;
