import {convertDateForDisplay, convertStringYMDToDate} from "../../containers/Helpers";
import {useEffect, useState} from "react";

function EventDateOnTimeline({dateStart, dateEnd, index}) {

    // const {convertDateForDisplay, convertStringYMDToDate} = Helpers();

    const [isToday, setIsToday] = useState(false);
    const [startDay, startMonth, , endDay, endMonth] = convertDateForDisplay(dateStart, dateEnd);
    const dayName = convertStringYMDToDate(dateStart).toDateString().substring(0, 3);
    const isSingleDayEvent = dateStart === dateEnd;
    const today = new Date();

    useEffect(() => {
        const startDate = convertStringYMDToDate(dateStart);
        const endDate = convertStringYMDToDate(dateEnd);

        const isTodayInRange = today >= startDate && today <= endDate; // endDate equals don't work because of time set to 00:00
        const isExactToday = today.toDateString() === startDate.toDateString() || today.toDateString() === endDate.toDateString();

        setIsToday((isTodayInRange || isExactToday));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={`card-date ${index} flex flex-col justify-center 
                        items-center rounded-full h-16 w-16 border-2 border-black
                         z-10 bg-custom-700 text-sm`}>
            <p>{startDay}</p>
            {isToday && <div className={"today w-2 h-2 rounded-full bg-green-500 border-animation"}
                             title="today">
            </div>}
            {!isToday && !isSingleDayEvent && <p className={"date-dash leading-[0.3rem]"}>-</p>}
            {!isSingleDayEvent && (startMonth !== endMonth ? <p>{endDay}.{endMonth}</p> : <p>{endDay}</p>)}
            {isSingleDayEvent && <p className={"text-xs text-primary"}>{dayName}</p>}
        </div>
    );
}

export default EventDateOnTimeline;