import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ProfileToggle from "../dancers/ProfileToggle";
import {MenuIcon} from '@heroicons/react/outline';
import Mobile from "../../containers/Mobile";

const Header = () => {
    const location = useLocation();
    const {mobileHeader} = Mobile();

    setTimeout(() => {
        mobileHeader();
    }, 1);

    useEffect(() => {
        window.addEventListener("resize", () => {
            document.querySelector(".menu").classList.add("hidden")
        })
    }, [])

    const toggleMenu = () => {
        document.querySelector(".menu").classList.toggle("hidden");
    }

    return (
        <>
            <header className="h-70 w-full p-2 pb-1 ">
                <div className="h-full bg-custom-800 rounded-md flex justify-between items-center">
                    <Link to="/">
                        <h1 className="text-primary text-4xl font-bold m-3 ml-5 ">SPOTEER</h1>
                    </Link>
                    <div
                        className={"menu absolute z-20 bg-custom-800 top-[74px] w-[98.4%] flex flex-col " +
                            "items-center gap-5 p-5 hidden rounded-md " +
                            "lg:relative lg:mr-10 lg:bg-transparent lg:flex lg:flex-row lg:top-0 lg:w-fit"}>
                        <Link to={"/"} className={"lg:hidden"} onClick={toggleMenu}>
                            <p className={`hover:text-primary font-bold
                            ${location.pathname === "/" ? "text-primary" : ""}`}>Events</p>
                        </Link>
                        <Link to={"/create-event"} className={"lg:hidden"} onClick={toggleMenu}>
                            <p className={`hover:text-primary font-bold
                            ${location.pathname === "/create-event" ? "text-primary" : ""}`}>Create Event</p>
                        </Link>

                        <Link to="/about" onClick={toggleMenu}>
                            <p className={`hover:text-primary font-bold
                            ${location.pathname === "/about" ? "text-primary" : ""}`}>About</p>
                        </Link>
                        <Link to="/contact" onClick={toggleMenu}>
                            <p className={`hover:text-primary font-bold 
                            ${location.pathname === "/contact" ? "text-primary" : ""}`}>Contact</p>
                        </Link>
                        <Link to={"/donate"} className={"lg:hidden"} onClick={toggleMenu}>
                            <p className={`hover:text-primary font-bold
                            ${location.pathname === "/donate" ? "text-primary" : ""}`}>Donate</p>
                        </Link>
                    </div>
                    <MenuIcon className="hamburger h-10 w-10 mr-4 lg:hidden" onClick={toggleMenu}/>
                    <ProfileToggle/>
                </div>
            </header>
        </>
    );
};

export default Header;
