import support from "../assets/images/support.png";
import {useEffect, useState} from "react";
import Mobile from "../containers/Mobile";

function Contact() {
    const {mobileContact} = Mobile();
    const [text, setText] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    });
    const [email, setEmail] = useState({
        to: "deen.bds@gmail.com",
        subject: "Pointer Support Request",
    });
    const [responseMessage, setResponseMessage] = useState(null);

    const url = "https://spoteer.cz:8443/send-email";
    //const url = "http://localhost:8080/send-email";

    useEffect(() => {
       mobileContact();
    }, [mobileContact]);

    const handleFormChange = (e) => {
        setText({...text, [e.target.id]: e.target.value});
        setEmail({
            ...email,
            "text": `${text.firstName} ${text.lastName} \n\n${text.email} \n\n${text.message}`
        });
    }

    const handleResponse = (response, color) => {
        setResponseMessage({text: response, color: color});
        setTimeout(() => {
            setResponseMessage(null);
        }, 5000);
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(email),
            });
            const data = await response;
            if (data.status === 200) {
                handleResponse("Email sent successfully", "green-500");
                document.querySelector("#form-support").reset();
            } else {
                handleResponse("An error occurred, please try again later", "red-500")
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={"contact-container p-10 flex flex-col h-[calc(100vh-80px)] lg:h-full bg-cover overflow-auto"}
             style={{backgroundImage: `url(${support})`}}>
            <div className={"xl:w-1/2 flex flex-col gap-5"}>
                <p className={"text-secondary font-bold text-xl"}>Need support ?</p>
                <p>Fill in the form to get in touch</p>
            </div>
            <form onChange={handleFormChange} onSubmit={sendEmail} id={"form-support"}>
                <div className={"flex flex-col gap-5 pl-6"}>
                    <div className={"xl:w-1/2 pt-10 flex gap-5"}>
                        <div className={"flex flex-col"}>
                            <label htmlFor="firstName" className={"font-bold"}>First Name</label>
                            <input type="text" id="firstName" className={"input"}/>
                        </div>
                        <div className={"flex flex-col"}>
                            <label htmlFor="lastName" className={"font-bold"}>Last Name</label>
                            <input type="text" id="lastName" className={"input"}/>
                        </div>
                    </div>
                    <div className={"flex flex-col "}>
                        <label htmlFor="email" className={"font-bold"}>Email</label>
                        <input type="email" id="email" className={"input w-2/3"} required={true}/>
                    </div>
                    <div className={"flex flex-col "}>
                        <label htmlFor="message" className={"font-bold"}>How can we help ?</label>
                        <textarea id="message" className={"input h-140 w-2/3"} required={true}/>
                    </div>
                    <button className={"button"}>Submit</button>
                </div>
            </form>
            <div className={"fixed bottom-24 right-10"}>
                {responseMessage &&
                    <p className={`text-${responseMessage.color}`}>{responseMessage.text}</p>}
            </div>
        </div>
    )
}

export default Contact;