import {useDispatch} from "react-redux";
import {
    fetchEvents,
    fetchEventsFailure,
    fetchEventsLoading,
    fetchEvent,
    fetchEventLoading,
    fetchEventFailure
} from "../redux/eventsSlice";
import {useNavigate} from "react-router-dom";
import {clearEventForm} from "../containers/Helpers";

function useEvents() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const url = "http://localhost:8080/events";
    // const url = "http://192.168.50.141:8080/events"; //2,4G
    // const url = "http://192.168.50.180:8080/events"; //5G
    // const url = "http://10.0.0.95:8080/events"; //lili
    const url = "https://spoteer.cz:8443/events";

    const getEvents = async () => {
        dispatch(fetchEventsLoading());
        try {
            const response = await fetch(url + "/active");
            const data = await response.json();
            dispatch(fetchEvents(data));
        } catch (error) {
            console.error('Error fetching events:', error);
            dispatch(fetchEventsFailure(error.message));
        }
    }

    const getEvent = async (id) => {
        dispatch(fetchEventLoading());
        try {
            const response = await fetch(url + `/${id}`);
            const data = await response.json();
            dispatch(fetchEvent(data));
            return data;
        } catch (error) {
            console.error('Error fetching events:', error);
            dispatch(fetchEventFailure(error.message));
        }
    }

    const validatePin = async (pin, id) => {
        return await fetch(url + "/pin", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pin: pin,
                eventId: id
            })
        }).catch(error => {
            console.error('Error:', error);
        });
    }

    const deleteEvent = (id) => {
        fetch(url + `/${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete event');
                }
                return response.text();
            })
            .then(data => {
                getEvents().then(r => console.log('Success:', data));
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const createFetch = (event, formData) => {
        if (event.pin.length === 4) {
            fetch(url, {
                method: "POST",
                body: formData,
            }).then(data => {
                if (data.status === 200) {
                    clearEventForm();
                    navigate(`/`)
                    console.log("Event created");
                } else {
                    alert("Something went wrong");
                }
            }).catch(error => console.log(error));
        } else {
            alert("Set pin code! It must be 4 digits long!");
        }
    }

    const updateFetch = (event, formData) => {
        fetch(url + "/update", {
            method: "PATCH",
            body: formData,
        }).then(data => {
            if (data.status === 200) {
                clearEventForm();
                navigate(`/events/${event.id}`)
                console.log("Event updated");
            } else {
                alert("Something went wrong");
            }
        }).catch(error => console.log(error));
    }

    const validateCode = (code) => {
        return fetch(url + "/code", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({"code": code})
        }).then(response => {
            return response
        }).catch(error => console.log(error));
    }

    return {getEvents, getEvent, validatePin, deleteEvent, createFetch, updateFetch, validateCode};
}

export default useEvents;