import {useEffect, useState} from "react";
import EventFactory from "./EventFactory";
import useEvents from "../../services/useEvents";
import {setIsEventCodeValid} from "../../redux/eventsSlice";
import {useSelector, useDispatch} from "react-redux";

const CreateEvent = () => {

    const props = {
        title: "Create Event",
        button: "Create Event",
    };
    const {createFetch, validateCode} = useEvents();
    const [eventCode, setEventCode] = useState("");
    const dispatch = useDispatch();
    const {isEventCodeValid} = useSelector(state => state.events);

    useEffect(() => {
        if (isEventCodeValid) {
            codeIsValid();
        }

        document.querySelector(".hint-question-mark").addEventListener("mouseenter", toggleHint);
        document.querySelector(".hint-question-mark").addEventListener("mouseleave", toggleHint);
    }, [isEventCodeValid]);


    const eventCodeHandler = (e) => {
        let code = e.target.value.toLowerCase();
        setEventCode(code);
        if (e.key === "Enter") checkCode();
    };

    const codeIsValid = () => {
        document.querySelector(".factory").classList.remove("hidden");
        document.querySelector(".code-check").classList.add("hidden");
    }

    const codeIsInvalid = () => {
        document.querySelector(".code-error").classList.remove("invisible");
        setTimeout(() => {
            document.querySelector(".code-error").classList.add("invisible");
        }, 2000);
    }

    const checkCode = () => {
        validateCode(eventCode)
            .then(data => {
                if (data.status === 200) {
                    dispatch(setIsEventCodeValid(true));
                    codeIsValid();
                } else {
                    codeIsInvalid();
                }
            }).catch(error => console.log(error));
    }

    const toggleHint = () => {
        document.querySelector(".hint-text").classList.toggle("hidden");
    }

    return (
        <div className={"p-4"}>
            <div className={"code-check"}>
                <div className={"m-3 gap-3"}>
                    <input type={"password"} placeholder={"Enter code"} id={"event-code"}
                           onKeyUp={eventCodeHandler} className={"input"}/>
                    <button onClick={checkCode} className={"button ml-3"}>Submit</button>

                </div>
                <p className={"hint-question-mark flex justify-center items-center " +
                    "bg-quaternary w-[20px] h-[20px] rounded-md ml-3 cursor-pointer"}>?</p>
                <p className={"hint-text text-secondary ml-4 hidden"}>Henry Link, Buddha Stretch, Loose Joint, and
                    Brooklyn Terry
                    are all
                    members of the Elite ...</p>
                <p className={"code-error ml-4 invisible text-red-700"}>Wrong code !</p>
            </div>
            <div className={"factory hidden"}>
                <EventFactory props={props} fetchFactory={createFetch}/>
            </div>
        </div>
    )
};

export default CreateEvent;
