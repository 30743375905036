import BasicInfoForm from "./createEventComponents/BasicInfoForm";
import PeopleForm from "./createEventComponents/PeopleForm";
import CreateCategory from "./createEventComponents/CreateCategory";
import SocialsForm from "./createEventComponents/SocialsForm";
import {useEffect} from "react";

function EventForm({
                       eventInfo,
                       setEventInfo,
                       handleChange,
                       setImage,
                       imagePreview, setImagePreview,
                       categories, setCategories,
                       judges, setJudges,
                       lecturers, setLecturers,
                       battleGuests, setBattleGuests,
                       mcs, setMcs,
                       djs, setDjs,
                       sponsors, setSponsors,
                       organizers, setOrganizers,
                       createUpdateEvent,
                       type, setType,
                       props,
                   }) {

    const toggleNavbar = (e) => {
        const navItems = {
            basicInfo: {
                nav: document.querySelector(".create-event-basic-info"),
                form: document.querySelector(".create-event-basic-info-form")
            },
            people: {
                nav: document.querySelector(".create-event-people"),
                form: document.querySelector(".create-event-people-form")
            },
            categories: {
                nav: document.querySelector(".create-event-categories"),
                form: document.querySelector(".create-event-categories-form")
            },
            socials: {
                nav: document.querySelector(".create-event-socials"),
                form: document.querySelector(".create-event-socials-form")
            }
        };

        Object.values(navItems).forEach(item => {

            // If the user tries to click on the people or categories tab without filling in the basic info
            if (e.target.classList.contains("text-gray-500")) {
                const type = document.querySelector(".type");
                type.style.border = "1px solid #FFEBB0";
                setTimeout(() => {
                    type.style.border = "";
                }, 1000);
                return;
            }

            if (e.target === item.nav) {
                item.nav.classList.add("bg-custom-700");
                item.form.classList.remove("hidden");
            } else {
                item.nav.classList.remove("bg-custom-700");
                item.form.classList.add("hidden");
            }
        });
    };

    useEffect(() => {
        const peopleNav = document.querySelector(".create-event-people");
        const categoriesNav = document.querySelector(".create-event-categories");
        if (type.length === 0) {
            peopleNav.classList.add(`text-gray-500`);
            categoriesNav.classList.add(`text-gray-500`);
        } else {
            peopleNav.classList.remove(`text-gray-500`);
            categoriesNav.classList.remove(`text-gray-500`);
        }
    }, [type]);

    const navbarItem = "w-1/4 text-center rounded-t-md p-1 font-bold cursor-pointer"

    return (
        <div>
            <div className={"h-10 bg-custom-700 rounded-md flex items-center"}>
                <p className={"ml-4 text-2xl font-bold"}>{props.title}</p>
            </div>
            <div className={"create-event-body h-event-panel bg-custom-800 mt-3 rounded-md "}>
                <div className={"create-event-navbar flex"}>
                    <div className={`create-event-basic-info ${navbarItem} bg-custom-700`}
                         onClick={toggleNavbar}>Basic info
                    </div>
                    <div className={`create-event-people ${navbarItem}`} onClick={toggleNavbar}>People</div>
                    <div className={`create-event-categories ${navbarItem}`}
                         onClick={toggleNavbar}>{type.includes("battle") ? "Categories" : "Styles"}</div>
                    <div className={`create-event-socials ${navbarItem}`} onClick={toggleNavbar}>Socials</div>
                </div>
                <div className={"create-event-forms bg-custom-700 h-create-event-panel overflow-auto rounded-b-xl p-2"}>
                    <BasicInfoForm eventInfo={eventInfo} setEventInfo={setEventInfo} setImage={setImage}
                                   setImagePreview={setImagePreview}
                                   imagePreview={imagePreview} type={type} setType={setType}
                                   handleChange={handleChange}/>
                    <PeopleForm judges={judges} setJudges={setJudges} lecturers={lecturers}
                                setLecturers={setLecturers} battleGuests={battleGuests}
                                setBattleGuests={setBattleGuests} mcs={mcs} setMcs={setMcs} djs={djs}
                                setDjs={setDjs} sponsors={sponsors} setSponsors={setSponsors}
                                organizers={organizers} setOrganizers={setOrganizers} type={type}/>
                    <CreateCategory categories={categories} setCategories={setCategories} type={type}/>
                    <SocialsForm createUpdateEvent={createUpdateEvent}
                                 event={eventInfo} props={props}
                                 handleChange={handleChange}/>
                </div>
            </div>
        </div>
    )
}

export default EventForm;