import React from 'react';

const Modal = ({isOpen, onClose, imageSrc}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex w-full justify-center z-50
                        overflow-auto bg-black bg-opacity-80 "
             onClick={onClose}>
            <div className="p-4 rounded-md z-10">
                <img src={imageSrc} alt="Event" className="rounded-md"/>
            </div>
        </div>
    );
};

export default Modal;